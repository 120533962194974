import React, { useContext } from 'react';
import { Outlet } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { SiteNav } from "./SiteNav";
import { DarkModeContext } from '../../App'


export const Layout = () => {
  const {darkModeState, _setDMS} = useContext(DarkModeContext)


  return (
    <div>
        <SiteNav />
        <Container fluid>
            <Row>
              <Col>
                  <Outlet/>
              </Col> 
            </Row>
        </Container>
    </div>
  );
}
