import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import type { Artifact } from '../types/Artifact'

interface ArtifactsState {
  artifacts: Artifact[],
}

const initialState: ArtifactsState = {
  artifacts: [],
}

export const artifactsSlice = createSlice({
  name: 'artifactsData',
  initialState,
  reducers: {
    loadArtifacts: (state, action: PayloadAction<[string, Artifact[]]>) => {
      let artifacts = action.payload[1].filter(newArt => state.artifacts.findIndex(art => newArt.checksum == art.checksum) == -1) 
      return {...state, artifacts: [...state.artifacts, ...artifacts]}
    }
  }

})


export const { loadArtifacts } = artifactsSlice.actions


export const allArtifacts = (state: RootState) => {
  return state.artifactsData.artifacts
}


export const makeSelectedArtifactsForVis = () => {
  const selectedArtifactsForVis = createSelector(
    [
      state => state.artifactsData.artifacts,
      (state, selection) => selection
    ],
    (artifacts, selection) => {
      let output: Artifact[] = []
      artifacts.forEach((artifact) => {
        if(selection.checksums.includes(artifact.checksum) && 
           !output.map(art => art.checksum).includes(artifact.checksum)) {
            output.push(artifact)
        }
      })
      return output
    }
  )
  return selectedArtifactsForVis
}

export default artifactsSlice.reducer
