import React, { useEffect, useRef }  from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { SegmentCoords, SeriesConfig } from '../types/SeriesConfig'
import { Tag, Icon } from '@blueprintjs/core';
import { loadDataSets, makeFilterNameSelector, makeDataSetSelector } from '../features/dashboardDataSlice'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


export const SeriesTag = ({series}:{series: SeriesConfig}) => {

  const dataSelector = makeDataSetSelector()
  const data = useAppSelector(state => dataSelector(state, [series]))

  const filterNameSelector = makeFilterNameSelector()
  const filterName = useAppSelector(state => filterNameSelector(state, series?.reportId || ''))
  

  const seriesText = () => {
    if(data.length > 0) {
      const dataset = data[0].datasets.find(ds => ds.id == series.dataSetId)

      return `${filterName} - ${data[0].name} - ${dataset?.label.split(' - ').slice(-2).join(' - ') || ''}`
    }
  }
  
  //useEffect(() => {console.log(data)}, [data])
  return (
    <Row>
      <Col>
        <Tag minimal={true} fill={true}>{seriesText()}</Tag>
      </Col>
      <Col>
        <Icon icon="chart" style={{color: series?.borderColor}}/>
      </Col>
    </Row>
  )

}

