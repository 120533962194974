import  React, {useState, useEffect}  from 'react';
import { Visualization } from '../types/Visualization'
import { Tree, TreeNode, Button, RadioGroup, Radio, Checkbox, NumericInput, Divider, Label, Classes, Card, HTMLSelect  } from '@blueprintjs/core'
import { VisType } from '../types/VisType'
import { SegmentCoords, SeriesConfig } from '../types/SeriesConfig'
import { Axis } from '../types/Axis';
import { selectedDataSetTree, selectedReportData, selectedSeries, selectSeriesById, selectedReport, selectedVis, updateVis as updateReportVis} from '../features/reportsSlice'
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RgbaColorPicker, RgbaColor} from "react-colorful";
import { v4 } from 'uuid'
import { DataTreeNode } from '../types/DataTreeNode'
import { AxisOption, AxisOptionList } from '../types/AxisOptionList'
import { selectedDashboard, updateDashboardVis } from '../features/dashboardSlice'
import { Dashboard } from '../types/Dashboard'
import { Report } from '../types/Report'
import { loadSegmentIndices, loadDataIndices, selectDataIndices, makeSegmentIndicesSelector, makeDataSetSelector } from '../features/dashboardDataSlice'
import { useLazyGetDataSegmentIndexQuery, useGetDataIndexQuery } from '../services/dataApi'


export const SeriesConfigRenderer2 = ({seriesConfigId, visualization, groupType}:{seriesConfigId: string, visualization: Visualization, groupType: string}) => {

  const vis = visualization
  const dispatch = useAppDispatch()
  const reportData = useAppSelector(selectedReportData)
  const dataIndices = useAppSelector(selectDataIndices)


  let group: Dashboard|Report|null|undefined
  const report = useAppSelector(selectedReport);
  const dashboard = useAppSelector(selectedDashboard);

  const series: SeriesConfig = visualization.series.find(seriesConf => seriesConf.id == seriesConfigId) || {} as SeriesConfig

  const [ segmentCoords, setSegmentCoords] = useState({} as SegmentCoords)
  useEffect(() => {
    setSegmentCoords({
      reportId: series?.reportId,
      dataSegmentIndex:  series?.dataSegmentIndex
    })
  }, [series, setSegmentCoords])


  let updateVis: any
  if (groupType == 'Report') {
    group = report
    updateVis = updateReportVis
  } else {
    group = dashboard
    updateVis = updateDashboardVis
  }

  const { data: dataIndexData, error: dataIndexError, isLoading: dataIndexIsLoading } = useGetDataIndexQuery(null)
  useEffect(() => {
    if(dataIndexData) {
      dispatch(loadDataIndices(dataIndexData)) 
     }
  }, [dataIndexData, dispatch])


  const segmentIndicesSelector = makeSegmentIndicesSelector() 
  const segmentIndices = useAppSelector(state => segmentIndicesSelector(state, segmentCoords))
  const [getDataSegmentIndTrigger, getDataSegmentIndResult, lastgetDataSegmentInd] = useLazyGetDataSegmentIndexQuery();


  useEffect(() => {
    if (typeof segmentCoords.reportId == 'string'
        && ( typeof segmentCoords.dataSegmentIndex == 'string' || typeof segmentCoords.dataSegmentIndex == 'number' )
        && segmentCoords.dataSegmentIndex !== '' ) {
      getDataSegmentIndTrigger([segmentCoords.reportId, segmentCoords.dataSegmentIndex])
    }
  }, [segmentCoords])



  useEffect(() => {
    if(getDataSegmentIndResult.status=='fulfilled' && getDataSegmentIndResult.data !== undefined){
      dispatch(loadSegmentIndices(getDataSegmentIndResult.data))
    }
  }, [getDataSegmentIndResult] )

  const updateSeriesFilter = (event: any, series: SeriesConfig) => {
    setSelectionPath('')
    seriesUpdater(series.id, {...series, dataSetId: '', reportDataId: '', dataSegmentIndex: '', reportId: event.target.value })
   
  }

  const updateSeriesQuery = (event: any, series:SeriesConfig) => {
    const name = dataIndices.find(di => di.report_uuid == series.reportId)?.data_segments.find(ds=> ds.data_segment_index == event.target.value)?.name || ''


    setSelectionPath(name)
    seriesUpdater(series.id, {...series, dataSetId: '', reportDataId: '', dataSegmentIndex: event.target.value })

  }

  

  /****************
  /* State and selection for Analysis Subject
  /******************/


  const [selectionPath, setSelectionPath] = useState('')

  const subjectList = () => {
    let nameList:{label: string, value:string}[] = Array.from(new Set(segmentIndices.map(index => index.name.split(' - ')[1]))).map(name => ({label: name as string, value: name as string}))
    return nameList
  }

  const requiresSecondSubject = () => {
    return selectionPath.split(' - ')[1] == 'Entities'
  }


  const subjectList2 = () => {
    let nameList:{label: string, value:string}[] = Array.from(new Set(segmentIndices.filter(si => {
      const basePath = selectionPath.split(' - ').slice(0,2).join(' - ')
      return si.name.indexOf(basePath)>-1
    }).map(index => index.name.split(' - ')[2]))).map(name => ({label: name as string, value: name as string}))
    return nameList
  }

 
  //set the subject if engough has already been specified have been
  useEffect(() => {
     const index = segmentIndices.find(index => index.report_id == series.reportId && 
                                                index.data_segment_index == series.dataSegmentIndex &&
                                                index.id == series.reportDataId //query hash+set encoded
                                                )
     if(index){
        setSelectionPath(index.name)
        console.log(selectionPath)
      }
  }, [series, segmentIndices, setSelectionPath])

 
  const secondarySubjectTypes = ['Entities']

  const updateSeriesSubjectBase = (event: any, series: SeriesConfig) => {
    setSelectionPath(selectionPath.split(' - ').slice(0, 1).concat([event.target.value]).join(' - '))
    seriesUpdater(series.id, {...series, dataSetId: '', reportDataId: '' })
  }

  const updateSeriesSubjectInternal = (event: any, series: SeriesConfig) => {
    setSelectionPath(selectionPath.split(' - ').slice(0, 2).concat([event.target.value]).join(' - '))
    seriesUpdater(series.id, {...series, dataSetId: '', reportDataId: '' })
  }


  // setup the group by lists

  const groupByList = () => {
    let subjectLastElement = 2
    if(requiresSecondSubject()) {
      subjectLastElement +=1
    }
    const indices = segmentIndices.filter(si => si.name.indexOf(selectionPath.split(' - ').slice(0, subjectLastElement).join(' - ')) > -1)
    return indices.map(si => {
            return {
              label: si.name.split(' - ').slice(-1),
              value: si.id
            }

      }) 

  }

  //update the goup by
  const updateSeriesGroupBy = (event: any, series: SeriesConfig) => {
    seriesUpdater(series.id, {...series, dataSetId: '', reportDataId: event.target.value })
  }


  // setup the final data set list
  // if the series has a reportDataId (and the other lower level ids already) .. then the panel will have
  // fetched that data
  const dataSelector = makeDataSetSelector()
  const dataSets = useAppSelector(state => dataSelector(state, [{...series}]))

  const dataSetList = () => {
    if(dataSets.length > 0) {
      return dataSets[0].datasets.map(ds => ({label: ds.label.split(' - ').slice(-2).join(' - '), value: ds.id}))
    } else {
      //pannel not requesting new data yet
    }
    return []
  }

  const dataSetTree = useAppSelector(selectedDataSetTree)
  const [localTree, setLocalTree] = useState<DataTreeNode[]>([])
  const [selected, setSelected] = useState<string[]>([])
  const [expandedPath, setExpandedPath] = useState<string[]>([])

  const nodeCopy = (node, selected) => {
    let copy = {}
    if (selected.length == 0) {
      copy = { ...node, childNodes: node.childNodes?.map(childNode => nodeCopy(childNode, selected))}

    } else {
      copy = { ...node, isSelected: (node.rdId == selected[0] && node.id == selected[1]),  childNodes: node.childNodes?.map(childNode => nodeCopy(childNode, selected))}

    }
    return copy as DataTreeNode
  }

  useEffect(() => {
    if(dataSetTree) {
      let newLocalTree = dataSetTree.map(node => nodeCopy(node, selected))
      if (expandedPath[0] !== undefined &&  newLocalTree?.[expandedPath[0]]) {
        newLocalTree[expandedPath[0]]
          .isExpanded = true
      }
      if (expandedPath[1] !==undefined  && newLocalTree?.[expandedPath[0]]?.childNodes[expandedPath[1]]) {
        newLocalTree[expandedPath[0]]
          .childNodes[expandedPath[1]]
          .isExpanded = true
      }
      if (expandedPath[2] !== undefined && newLocalTree?.[expandedPath[0]]?.childNodes[expandedPath[1]]?.childNodes[expandedPath[2]]) {
        newLocalTree[expandedPath[0]]
        .childNodes[expandedPath[1]]
        .childNodes[expandedPath[2]]
        .isExpanded = true
      }
      if (expandedPath[3] !== undefined && newLocalTree[expandedPath[0]]?.childNodes[expandedPath[1]]?.childNodes[expandedPath[2]]?.childNodes[expandedPath[3]]) {

        newLocalTree[expandedPath[0]]
          .childNodes[expandedPath[1]]
          .childNodes[expandedPath[2]]
          .childNodes[expandedPath[3]]
          .isExpanded = true
      }
      
      setLocalTree(newLocalTree)
    }

  }, [dataSetTree, expandedPath, selected])

  useEffect(() => {
    const path = (nodes, selected, newPath) => {
      return  nodes.map((node, index) => {
        if (node.rdId == selected[0] && node.id == selected[1]) {
          return newPath.concat([index])
        } else if(node.childNodes?.length > 0) {
          return path(node.childNodes, selected, newPath.concat([index]))
        } else {
          return null
        }
      }).filter(x => x !== null).flat()
    }
    // initialize from basic localTree and series
    if (series && expandedPath.length == 0 && selected.length == 0 && localTree.length != 0) {
      if (series.reportDataId && series.dataSetId) {
        let newSelected:string[] = []
        newSelected[0] = series.reportDataId
        newSelected[1] = series.dataSetId
        setSelected(newSelected)
        let newExpandedPath = path(localTree, newSelected, [])
        if (newExpandedPath) {
          setExpandedPath(newExpandedPath)
        }
      }
    }
    // something else changed
    // set the selected element
    if ((selected.length == 2 && (selected[0] !==  series.reportDataId || selected[1] !== series.dataSetId)) || (series && expandedPath.length !== 0 && localTree.length != 0 && selected.length == 0 && series.reportDataId != null && series.dataSetId != null)) {
        let newSelected:string[] = []
        newSelected[0] = series.reportDataId
        newSelected[1] = series.dataSetId
        setSelected(newSelected) 
    }
  },
  [series, selected, expandedPath, localTree, setSelected, setExpandedPath])

//  const series = useAppSelector(state => selectSeriesById(state, vis.id, params.seriesConfigId))
  /**
  * Methods for series properties
  */

  /**
  * Generic updater for a Series Property
  */
  const seriesUpdater = (seriesId: string, seriesConfig: SeriesConfig) => {
    const seriesIndex = getSeriesIndexById(seriesId)
    if (seriesIndex > -1 && vis?.series && group?.id) {
      let series = [...vis.series]
      series[seriesIndex] = seriesConfig
      dispatch(updateVis([group.id, {...vis, series: series}])) 
    }
  }

  /**
  * Find a Series Index by Id
  */
  const getSeriesIndexById = (seriesId: string) => {
    if ( group && vis ){
      return vis.series.findIndex(s => s.id == seriesId)
    } else {
      return -1
    }
  }


/*

  const dataSetOptions = (reportDataId: string) => {
    if (reportData?.datasets) {
      return reportData.datasets.map( ds => {
        return {
          value: ds.id,
          label: ds.name
        }
      });

    } else {
      return [];
    }
  }
*/  

  const findOrientation = (series: SeriesConfig) => {
    if (series.xAxisID) {
      return 'x'
    } else {
      return 'y'
    }
  }

  const axisList = (series: SeriesConfig):AxisOptionList => {
    if (vis) {
        return {
          'x': vis.xAxes.map( axis =>  { return {label: axis.name, value: axis.name} }), 
          'y': vis.yAxes.map( axis =>  { return {label: axis.name, value: axis.name} })
        } as AxisOptionList
    } else {
      return {
        x: [],
        y: []
      } as AxisOptionList;
    }
  }

  const updateSeriesReportData = (event: any, series: SeriesConfig) => {
    seriesUpdater(series.id, {...series, reportDataId: event.target.value})
  }

  const updateSeriesDataSet = (event: any, series: SeriesConfig) => {
    seriesUpdater(series.id, {...series, dataSetId: event.target.value})
  }

  const handleNodeExpand = (node, nodePath, series) => {
    if (expandedPath.join(',') == nodePath.join(',')) {
      nodePath = nodePath.slice(0, -1)
    }
    setExpandedPath(nodePath)
  }
  const handleNodeClick = (node, nodePath, series) => {
    if (node.childNodes) {
      handleNodeExpand(node, nodePath, series)  
    } else {
      if (series.reportDataId == node.rdId && series.dataSetId ==  node.id) {
        seriesUpdater(series.id, {...series, reportDataId: null, dataSetId: null})
      } else {
        seriesUpdater(series.id, {...series, reportDataId: node.rdId, dataSetId: node.id})
      }
    }
  }


  const updateSeriesOrientationToggle = (event: any, series: SeriesConfig) => {
    const orientation = findOrientation(series);
    if (orientation !== event.currentTarget.value) {
      let tempSeries: SeriesConfig;
      if (event.currentTarget.value === 'y') {
        tempSeries = {...series, yAxisID: axisList(series).y?.[0]?.value }
        delete tempSeries.xAxisID
      } else {
        tempSeries = {...series, xAxisID: axisList(series).x?.[0]?.value }
        delete tempSeries.yAxisID
      }
      seriesUpdater(series.id, tempSeries)
    }
  } 

  const updateSeriesAxis =  (event: any, series: SeriesConfig) => {
    const orientation = findOrientation(series);
    if (orientation === 'x') {
      seriesUpdater(series.id, {...series, xAxisID: event.target.value})
    } else {
      seriesUpdater(series.id, {...series, yAxisID: event.target.value})
    }
  }


  const updateSeriesType = (event: any, series: SeriesConfig) => {
    seriesUpdater(series.id, {...series, type: event.target.value})
  }

  const updateSeriesThickness = (event: any, series: SeriesConfig) => {
    seriesUpdater(series.id, {...series, barThickness: event.target.value})
  }

  const updateSeriesColor = (color: any, series: SeriesConfig, element: string) => {
    const colorString = `rgba(${color.r},${color.g},${color.b},${color.a})`
    if (element === 'background') {
      seriesUpdater(series.id, {...series, backgroundColor: colorString})
    } else {
     seriesUpdater(series.id, {...series, borderColor: colorString})
    }
  }



  const stringToRGBA = (colorString: string) => {
    const regex = /rgba?\((\d+),(\d+),(\d+),?(\d.?\d*)?\)/
    const matches = colorString.match(regex)
    if(matches) {
      let rgba = {r: +matches[1], g: +matches[2], b: +matches[3], a: null}
      if (matches[4] === undefined) {
        return {...rgba, a: 1.0}
      } else {
         return {...rgba, a: +matches[4]}
      }
    } else {
      return {r: 0, g: 0, b: 0, a: 0.1}
    }
  }

  const seriesRGBColor = (series: SeriesConfig) => {
    let borderColor:RgbaColor;
    if ( series.borderColor ) {
      borderColor = stringToRGBA(series.borderColor)
    } else {
      borderColor = {r: 0, g: 0, b: 0, a: 0.1}
    }
    let backgroundColor:RgbaColor;
    if ( series.backgroundColor ) {
      backgroundColor = stringToRGBA(series.backgroundColor)
    } else {
      backgroundColor = {r: 0, g: 0, b: 0, a: 0.1}
    }
    return [backgroundColor, borderColor]
  }

  const thicknessDisabled = (series: SeriesConfig) =>{
    if (series.type === 'bar' || ((series.type === null || series.type === '' || series.type === undefined) && vis && vis.chartType === 'Bar')){
      return false
    } else {
      return true
    }
  }

  return (
    <div>
      <Divider />
      { series &&
        <div>
        <Row>
          <Col>
            <h6> Select Data Set </h6>
              <Row>
                <Col >
                  <Label>Filter
                    <HTMLSelect
                      options={[{label: 'Select a filter', value: ''}].concat(dataIndices.map(di => ({label: di.report_name, value: di.report_uuid})))}
                      value={series.reportId || ''}
                      onChange={(event) => {updateSeriesFilter(event, series)}} 
                    />
                    </Label>
                </Col>

                <Col >
                  <Label>Query
                    <HTMLSelect
                      options={[{label: 'Select a query', value: ''} as {label: string, value: string|number}].concat(dataIndices.find(di => di.report_uuid == series.reportId)?.data_segments.map(ds => ({label: ds.name, value: ds.data_segment_index})) || [])}
                      value={series.dataSegmentIndex == undefined ? '' : series.dataSegmentIndex}
                      onChange={(event) => {updateSeriesQuery(event, series)}} 
           
                    />
                  </Label>
                </Col>
                <Col >
                  <Label>Subject
                    <HTMLSelect
                      options={[{label: 'Select Subject', value: ''}].concat(subjectList() || [])}
                      value={selectionPath.split(' - ')[1] || ''}
                      onChange={(event) => {updateSeriesSubjectBase(event, series)}} 
           
                    />
                  </Label>
                </Col>
                { requiresSecondSubject() &&
                <Col >
                  <Label>Internal Subject
                    <HTMLSelect
                      options={[{label: 'Select Internal Subject', value: ''}].concat(subjectList2() || [])}
                      value={selectionPath.split(' - ')[2] || ''}
                      onChange={(event) => {updateSeriesSubjectInternal(event, series)}} 
           
                    />
                  </Label>
                </Col>
                }
                <Col >
                  <Label>Group By
                    <HTMLSelect
                      options={[{label: 'Select Group', value: ''}].concat(groupByList() || [])}
                      value={series.reportDataId || ''}
                      onChange={(event) => {updateSeriesGroupBy(event, series)}} 
           
                    />
                  </Label>
                </Col>
                <Col >
                  <Label>Data Stats
                    <HTMLSelect
                      options={ [{label: 'Select a Stat', value: ''}].concat(dataSetList())}
                      value={series.dataSetId || ''}
                      onChange={(event) => {updateSeriesDataSet(event, series)}} 
           
                    />
                  </Label>
                </Col>


            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
              <RadioGroup
                  label="Orientation"
                  selectedValue={findOrientation(series)}
                  onChange={(event) => updateSeriesOrientationToggle(event, series)}
              >
                  <Radio label="X" value="x" />
                  <Radio label="Y" value="y" />
              </RadioGroup>
          </Col>
          <Col>
            <Label>Axis
               <HTMLSelect 
                options={axisList(series)[findOrientation(series)]}
                value={findOrientation(series) === 'x' ? series.xAxisID : series.yAxisID}
                onChange={(event) => updateSeriesAxis(event, series)}
                fill={false}
              /> 
            </Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Type
              <HTMLSelect
                options={Object.keys(VisType).map(t => { return { label: t, value: t.toLowerCase()}}).concat( [{label: "Default", value: ''}])}
                value={series.type || ''}
                onChange={(event) => updateSeriesType(event, series)}
              >
              </HTMLSelect>
            </Label>
          </Col>
          <Col>
            <Label>Bar Width
              <input className={Classes.INPUT + " bp4-fill"} type="text" placeholder="#" dir="auto" value={series.barThickness || ''} disabled={thicknessDisabled(series)}
                onChange={(event)=> updateSeriesThickness(event, series)}
              />
            </Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Background
              <RgbaColorPicker className="series-config-color" color={seriesRGBColor(series)[0]} 
                onChange={(color) => updateSeriesColor(color, series, 'background')}
              /> 
            </Label>
          </Col>
          <Col>
            <Label>Border
              <RgbaColorPicker className="series-config-color" color={seriesRGBColor(series)[1]} 
                onChange={(color) => updateSeriesColor(color, series, 'border')}
              />
            </Label>
          </Col>
        </Row>
        </div>
    }
    </div>


  )
}

