import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import { Org } from '../types/Org'
import type { InternalUser } from '../types/InternalUser'
import type { OrgUser } from '../types/OrgUser'
// Define a type for the slice state
interface OrgsState {
  orgUsers: InternalUser[]
  mySelectedOrgId: string | null,
  me: OrgUser|null,
  myOrgs: {role: string, org: Org}[]
}

// Define the initial state using that type
const initialState: OrgsState = {
  orgUsers: [],
  me: null,
  mySelectedOrgId: null,
  myOrgs: []
}

export const orgsSlice = createSlice({
  name: 'orgsData',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    loadOrgUsers: (state, action: PayloadAction<InternalUser[]>) => {
      return {...state, orgUsers: action.payload}
    },
    loadMe: (state, action: PayloadAction<OrgUser>) => {
      return {...state, me: action.payload}
    },
    loadMeOrgs: (state, action: PayloadAction<{role: string, org: Org}[]>) => {
      return {...state, myOrgs: action.payload}
    },
    selectMyOrg: (state, action: PayloadAction<string>) => {
      return {...state, mySelectedOrgId: action.payload}
    },
    selectOrg: (state, action: PayloadAction<string>) => {
      return {...state, selectedOrgId: action.payload}
    }
  },
})

export const { loadOrgUsers, loadMe, loadMeOrgs, selectMyOrg, selectOrg } = orgsSlice.actions

export const selectMe = (state: RootState) => state.orgsData.me

export const selectOrgUsers = (state: RootState) => state.orgsData.orgUsers

export const mySelectedOrg = (state: RootState) => {
  if (state.orgsData.mySelectedOrgId) { 
    return state.orgsData.myOrgs.find( o => o.org.uuid == state.orgsData.mySelectedOrgId)
  } else {
    return null;
  }
}

export const mySelectedOrgId = (state: RootState) => state.orgsData.mySelectedOrgId


export default orgsSlice.reducer
