import { React, useEffect, useRef }  from 'react';
import { Label, ButtonGroup, Divider, Callout, Button } from '@blueprintjs/core'
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'chart.js/auto';
import 'chartjs-adapter-moment';
import { Pie, Chart, Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ChartOptions } from 'chart.js'
import { updateReportArtifactSelections, selectedReportId,  selectedReportData, selectedVis } from '../features/reportsSlice';
import { Vizualization } from '../types/Visualization';
import { transpose } from 'matrix-transpose'
import { Base64 } from 'js-base64';
import { selectPlugin } from './selectPlugin'

export const Visualizer = () => {
  const chartRef = useRef(null);

  const dispatch = useAppDispatch()

  const reportDataRaw = useAppSelector(selectedReportData);
  const visualization = useAppSelector(selectedVis)
  const reportId = useAppSelector(selectedReportId)

  const getReportData = (reportDataId: string) => {
    if (reportDataRaw) {
      return reportDataRaw.find( rd => rd.id == reportDataId)
    } else {
      return null
    }
  }

  const getDataSet = (reportDataId: string, dataSetId: string) => {
    const reportData = getReportData(reportDataId)
    if (reportData && reportData.datasets) {
      return reportData.datasets.find(ds => ds.id == dataSetId)
    } else {
      return null;
    }
  }
 
  const trimLabels = (labels: string[]) => {
    if(labels.length > 1) {
      let labelsMatrix = labels.map(label => {
        return label.split(' - ')
      })
      let segmentsToRemove = []
      labelsMatrix[0].forEach(segment => {
        let remove = true
        labels.forEach(label => {
          if (label.indexOf(segment) === -1){
            remove = false
          }
        })
        if(remove === true) {
          segmentsToRemove.push(segment)
        }
      })
      let trimedLabels = []
      labels.forEach(label => {
        let newLabel = label
        segmentsToRemove.forEach(segment => {
          newLabel = newLabel.replace(segment, '')
          if(newLabel.endsWith(' - ')) {
            newLabel = newLabel.slice(0, -3)
          } 
          if(newLabel.startsWith(' - ')) {
            newLabel = newLabel.slice(3)
          } 
          newLabel = newLabel.replace(' -  - ', ' - ') 
        })
        trimedLabels.push(newLabel);
      })
      return trimedLabels
    } else {
      return labels
    }
  }
 
  const genLabels = (visualization: Visualization) => {
    let reportDataSetIds = [];
    for (const series of visualization.series) {
      reportDataSetIds.push(series.reportDataId)
    }
    const uniqueReportDataIds =  new Set([...reportDataSetIds]);
    if (visualization.concatenate) {
      let labels = []
       for (const id of reportDataSetIds) {
        const reportData = getReportData(id)
        if (reportData) {
          labels = labels.concat(reportData.labels)
        }
      } 
      return trimLabels(labels)
    }
    if (uniqueReportDataIds.length > 1){
      // we must pad with null data to merge the visuazation
      return [];
    } else {
      // we got the easy case
      for (const id of uniqueReportDataIds) {
        const reportData = getReportData(id)
        if (reportData) {
          return reportData.labels
        } else {
          return [];
        }
      }
    }
  }

  const genDatasets = (visualization: Visualization) => {
    let dataSets = [];
    if (visualization.concatenate) {
      let dataSet = {
        data: [],
        label: '',
        backgroundColor: [],
        borderColor: [],
        borderWidth: null,
        barThickness: null
      }
      for (const series of visualization.series) {
        const data = getDataSet(series.reportDataId, series.dataSetId)
        if(data) {
          dataSet = { 
            data: [...dataSet.data, data.data], 
            label: data.label, 
            borderColor: [...dataSet.borderColor, series.borderColor],
            backgroundColor: [...dataSet.backgroundColor, series.backgroundColor],
          }
        }
      }
      return [dataSet]
    }
    for (const series of visualization.series) {
      const data = getDataSet(series.reportDataId, series.dataSetId)
      if(data) {
        const set = {data: data.data, label: data.label, ...series}
        dataSets.push(set)
      }
    }
    const origSeriesLabels = dataSets.map(ds => ds.label)
    const newSeriesLabels = (trimLabels(origSeriesLabels))
    if(origSeriesLabels !== newSeriesLabels){
      let newDataSets = []
      dataSets.forEach((ds, index) => {
        const set = {...ds, label: newSeriesLabels[index]}
        newDataSets.push(set)
      })
      return newDataSets;
    }
    return dataSets;
  }

  const genVisualizationData = (visualization: Visualization) => {
    // check all series to see if they have the same labels
    // if not interpolate missing data as null
    const labels = genLabels(visualization)
    const datasets = genDatasets(visualization)
    let visData = {
      labels: labels,
      datasets: datasets
    }
    if (visualization.sortBy) {
      const sorted = sortVisData(visualization, visData)
      visData = {
        labels: sorted[0],
        datasets: datasets.map((ds, index) => {
          return {...ds, data: sorted[index+1]}
        })
      }
    }
    return visData
  }

  const sortVisData = (vis: Visualization, visData: any)  => {
    let visDataMatrix = [
      visData.labels,
      ...visData.datasets.map(dataset => dataset.data)
    ]
    if (!visDataMatrix[0]) {
      return visDataMatrix
    } 
    const sorted = transpose(visDataMatrix).sort((a,b) => {
      const sortByInt = parseInt(vis.sortBy)
      a = parseFloat(a[sortByInt + 1]) || 0
      b = parseFloat(b[sortByInt + 1]) || 0

      if (a < b) {
        return vis.orderBy === 'DESC' ? 1 : -1
      } else if (a > b) {
        return vis.orderBy === 'DESC' ? -1 : 1
      } else {
        return 0
      }
    })
    return transpose(sorted)
  }
  const handleSelect = (points) => {
    const selections = []
    visualization.xAxes.forEach((axis) => {
      const axis_name = axis.name
      const start_end = points.flat().filter(x => x.key == axis_name).map(point => point.value).sort()
      visualization.series.forEach(ser => {
        if(start_end[0] != start_end[1]) {
          const selection = {
            visualizationUUID: visualization.id,
            uuid: ser.reportDataId.split('_')[0],
            start: start_end[0],
            end: start_end[1],
            checksums: []          
          }
          if (selections.findIndex((sel) => sel.uuid == selection.uuid) < 0) {
            selections.push(selection)
          }
        }
      })
      

    })
    dispatch(updateReportArtifactSelections([reportId, selections]))

  }

  const genOptions =  (visualization: Visualization) => { 
    let options = {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: visualization.title
        },
        legend: {
          events: ['click']
        },
        select: {
          events: ['mousedown', 'mousemove', 'mouseout', 'mouseup' ],
          callback: handleSelect
        }
      },
      events: ['mousedown', 'mousemove', 'mouseout', 'mouseup', 'click' ],
      scales: {}
    } 
    for (const axis of visualization.xAxes) {
      if (visualization.chartType?.toLowerCase() !== "pie"
          && !axis.axisConfig.ticks 
          && axis.axisConfig.type !== 'time') {
        options.scales[axis.name] = {...axis.axisConfig,
          ticks: {
            callback: function(value, index, values){
              let label = this.getLabelForValue(value)
              if (typeof label === 'string' && label.length > 20) {
                return `${label.slice(0, 20)}...`
              } else {
                return label
              }
              return value
            }
          }
        }
      } else {
        options.scales[axis.name] = axis.axisConfig
      }
    }
    for (const axis of visualization.yAxes) {
      options.scales[axis.name] = { ...axis.axisConfig,
          max: parseFloatOrNull(axis.axisConfig.max),
          min: parseFloatOrNull(axis.axisConfig.min),
      }
    }
    if (visualization.xAxes.find(axis => axis.axisConfig.type == "time")) {
      options.plugins.select['enabled'] = true
    } else {
      options.plugins.select['enabled'] = false
    }
    ChartJS.register(selectPlugin)
    return options;
  }

  const parseFloatOrNull = (value) => {
    if (value == null) {
      return null 
    }
    else {
      return parseFloat(value)
    }
  }

  const visualizationTagRenderer = () => {
    switch (visualization.chartType) {
      case "Bar" || "bar": {
        return (
             <Bar
                ref={chartRef}
                data={genVisualizationData(visualization)} 
                options={genOptions(visualization)} 
                key={visualization.id}
                redraw={false}
                datasetIdKey="id"
              />
        );
      }
      case "Line" || "line": {
        return (
             <Line
                ref={chartRef}
                data={genVisualizationData(visualization)} 
                options={genOptions(visualization)} 
                key={visualization.id}
                redraw={false}
                datasetIdKey="id"
              />
        );
      }
      case "Pie" || "pie": {
        return (
             <Pie
                ref={chartRef}
                data={genVisualizationData(visualization)} 
                options={genOptions(visualization)} 
                key={visualization.id}
                redraw={false}
                datasetIdKey="id"
              />
        );
      }

    }
  }
/*
  let renderDownload = false
  useEffect(() => {
    if(visualization && chartRef?.current){
      console.log(chartRef.current)
      if (!renderDownload) {
        renderDownload = true

        console.log('Can Render Download')
      }
    }
  }, [visualization, chartRef, renderDownload])
*/
  const handleImage = () => {
    const elet = document.createElement("a");
    elet.href = chartRef.current?.toBase64Image()
    elet.setAttribute("download", `${visualization.title}.png`)
    elet.click()
  }

  const CSVData = () => {
    let chartData = genVisualizationData(visualization)
    let exportData = []
    exportData[0] = ["Name", ...chartData.labels]
    chartData.datasets.forEach(dataset => {
      exportData.push([dataset.label, ...dataset.data])
    })
    exportData = transpose(exportData)
    let csv = ""
    exportData.forEach(row => {
      let rowString = ""
      row.forEach(elet => {
        if (isNaN(elet)) {
          rowString += `"${elet}",`
        } else if (elet === null) {
          rowString += ","
        } else {
          rowString += `${elet},`
        }
      })
      csv += (rowString + "\n")
    })
    return `data:text/csv;base64,${Base64.encode(csv)}`
  }

  const handleCSV = () => {
    const elet = document.createElement("a");
    elet.href = CSVData()
    elet.setAttribute("download", `${visualization.title}.csv`)
    elet.click()
  }
  return (
    <Row>
      <Col sm={12}>
        <Row>
          <Col sm={12}>
            <Callout className="visualized">
              { reportDataRaw && visualization && visualizationTagRenderer() }
            </Callout>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
          { visualization &&
          <ButtonGroup className="data-download-options">
            <Button minimal={false} icon="media" onClick={handleImage} fill={false}>Image</Button>
            <Button minimal={false} icon="document" onClick={handleCSV} fill={false}>CSV</Button>
          </ButtonGroup>
          }
          </Col>
        </Row>
      </Col>
    </Row>
  );

}
