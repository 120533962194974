import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import type { User } from '../types/User'
// Define a type for the slice state
interface UserState {
  user: User | null
}

// Define the initial state using that type
const initialState: UserState = {
  user: null
}

export const userSlice = createSlice({
  name: 'userData',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    login: (state, action: PayloadAction<User>) => {
      state.user = {...state.user, ...action.payload}
    },
    logout: (state) => {
      state.user = null
    },
    setModule: (state, action: PayloadAction<number>) => {
      if (state.user) {
        state.user = {...state.user, filterModule: action.payload }
      }
    }
  },
})

export const { login, logout, setModule } = userSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectUser = (state: RootState) => state.userData.user

export default userSlice.reducer
