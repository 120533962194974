import  React, {useState, useEffect}  from 'react';
import { Visualization } from '../types/Visualization'
import { ButtonGroup, MultistepDialog, DialogStep, Tree, TreeNode, Button, RadioGroup, Radio, Checkbox, NumericInput, Divider, Label, Classes, Card, HTMLSelect  } from '@blueprintjs/core'
import { VisType } from '../types/VisType'
import { SeriesConfig } from '../types/SeriesConfig'
import { Axis } from '../types/Axis';
import { selectedReport, selectedVis, updateVis as updateReportVis } from '../features/reportsSlice'
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RgbaColorPicker } from "react-colorful";
import { v4 } from 'uuid'
import { SeriesConfigRenderer2 } from './SeriesConfigRenderer2'
import { selectedDashboard, updateDashboardVis } from '../features/dashboardSlice'
import { Dashboard } from '../types/Dashboard'
import { Report } from '../types/Report'
import { SeriesTag } from './SeriesTag'
 
enum PositionOption {
  default = "default",
  top = "top",
  left = "left",
  bottom = "nottom",
  right = "right",
  center = "center"
}

enum AxisTypeOption {
  default = "default",
  linear = "linear",
  logarithmic = "logarithmic",
  time = "time"
}

enum AxisTimeUnits {
  default = "default",
  millisecond = "millisecond",
  second = "second",
  minute = "minute",
  hour = "hour",
  day = "day",
  week = "week",
  month = "month",
  quarter = "quarter",
  year = "year"

}

export const VisualizationConfigurator2 = ({visualization, groupType}:{visualization: Visualization, groupType: 'reports'|'dashboards'}) => {
  const vis = visualization
  const dispatch = useAppDispatch()

  let group: Dashboard|Report|null|undefined
  const report = useAppSelector(selectedReport);
  const dashboard = useAppSelector(selectedDashboard);
  
  const [isOpen, setIsOpen] = useState(false)

  const [seriesOpen, setSeriesOpen] = useState<boolean[]>([])

  const toggleSeriesConf = (index: number) => {
    let series = [...seriesOpen]
    series[index] = series[index] ? false : true
    setSeriesOpen(series)
  }

  let updateVis: any;

  if (groupType == 'reports') {
    group = report
    updateVis = updateReportVis
  } else {
    group = dashboard
    updateVis = updateDashboardVis
  }
  const titleChanger = (event: any) => {
    if (vis && group?.id) {
      dispatch(updateVis([group.id, {...vis, title: event.target.value}]));
    }
  }

  const typeChanger = (event: any) => {
    if (vis && group?.id) {
      dispatch(updateVis([group.id, {...vis, chartType: event.target.value}]));
    }
  }


  /**
  * Methods for updating Axes and Their Configs
  */

  /**
  * Finds an axis if it exits in this visualization
  * @param axisId
  * @returns ['x'|'y', Axis]
  */
  const getAxisIndexById = (axisId: string):[string, number]|null => {
    if (vis) {
      let index = vis.xAxes.findIndex(ax => ax.id === axisId)
      if (index > -1) {
        return ['x', index]
      } else {
        index = vis.yAxes.findIndex(ax => ax.id === axisId)
        if (index > -1) {
          return ['y', index]
        } else {
          return null
        }
      }
    } else {
      return null
    }
  }


  /**
  * Changes the Axis Name
  */ 
  const axisNameChanger = (event: any, axis: Axis) => {
    axisPropsUpdater(axis.id, { name: event.target.value})
  } 

  /**
  * Changes the Axis Min
  */ 
  const axisMinChanger = (value: number, stringValue: string, axis: Axis) => {
    axisPropsUpdater(axis.id, { axisConfig: {...axis.axisConfig, min: stringValue}})
  } 

  /**
  * Changes the Axis Max
  */ 
 const axisMaxChanger = (value: number, stringValue: string, axis: Axis) => {
    axisPropsUpdater(axis.id, { axisConfig: {...axis.axisConfig, max: stringValue}})
  } 

  /**
  * Changes the Axis Title Enabled Prop
  */ 
  const axisTitleEnabledChanger = (event: any, axis: Axis) => {
    axisPropsUpdater(axis.id, { axisConfig: {...axis.axisConfig, title: {...axis.axisConfig.title,  display: event.target.checked}}})
  } 

  /**
  * Changes the Axis Title
  */ 
 const axisTitleChanger = (event: any, axis: Axis) => {
    axisPropsUpdater(axis.id, { axisConfig: {...axis.axisConfig, title: {...axis.axisConfig.title,  text: event.target.value}}})
  } 



  /**
  * Changes the Axis Position
  */ 
 const axisPositionChanger = (event: any, axis: Axis) => {
    if (event.target.value === "default") {
      let tempAxisProps = { axisConfig: {...axis.axisConfig, position: event.target.value == "default" ? null : event.target.value}}
      delete tempAxisProps.axisConfig.position
      axisPropsUpdater(axis.id, tempAxisProps)
    } else {
      axisPropsUpdater(axis.id, { axisConfig: {...axis.axisConfig, position: event.target.value}})
    }
  } 

  /**
  * AxisTypeChanger
  */
  const axisTypeChanger =  (event: any, axis: Axis) => {
    if (event.target.value === 'default')  {
      const {type, ...axisConfig } = axis.axisConfig
      axisPropsUpdater(axis.id, { axisConfig: axisConfig })
    } else {
      axisPropsUpdater(axis.id, { axisConfig: {...axis.axisConfig, type: event.target.value}})
    }
    
  }

  /**
  * AxisTimeUnitChanger
  */
  const axisTimeUnitChanger =  (event: any, axis: Axis) => {
    if (event.target.value === 'default')  {
      let newTime = {}
      let { time, ..._ } = axis.axisConfig
      if (time?.unit){
        let { unit, ...newTime } = time
      } 
      axisPropsUpdater(axis.id, {axisConfig: {...axis.axisConfig, time: {...newTime}}})
    } else {
      let time = axis.axisConfig.time || {}
      axisPropsUpdater(axis.id, { axisConfig: {...axis.axisConfig, time: {...time, unit: event.target.value}}})
    } 
  }

  /**
  * Axis Group/Stack/Layer
  */
  const axisComposerChanger = (event: any, axis: Axis) => {
    switch(event.target.value) {
      case 'stack':
        axisPropsUpdater(axis.id, { axisConfig: {...axis.axisConfig, grouped: false, stacked: true}})
        break;
      case 'group':
        axisPropsUpdater(axis.id, { axisConfig: {...axis.axisConfig, grouped: true, stacked: false}})
        break
      default:
        let newConfig = {...axis.axisConfig}
        delete newConfig.grouped
        delete newConfig.stacked
        axisPropsUpdater(axis.id, { axisConfig: {...newConfig}})
    }
  }


  /**
  * Create Axis
  */
  const createAxis = (orient: string) => {
    let axis:Axis = {
      name: '',
      id: v4(),
      axisConfig: {
      } 
    }
    if (orient == "x") {
      axis = {...axis, name: 'x-untitled'}
      if (vis && vis.xAxes.length === 0) {
        axis = {...axis, axisConfig: { stacked: true}}
      }
      if (group?.id && vis) {
        dispatch(updateVis([group.id, {...vis, xAxes: [...vis.xAxes, axis]}]))
      }
    } else {
      axis = {...axis, name: 'y-untitled'}
      if (group?.id && vis) {
        dispatch(updateVis([group.id, {...vis, yAxes: [...vis.yAxes, axis]}]))
      }
    }
    
  }

  /**
  * Remove Axis
  */
  const removeAxis = (axis: Axis) => {
    const axisIndex = getAxisIndexById(axis.id)
    if (axisIndex && group?.id) {
        switch (axisIndex[0]) {
        case 'x': {
          let xAxes = [...(vis?.xAxes || [])]
          xAxes.splice(axisIndex[1], 1)
          dispatch(updateVis([group.id, {...vis, xAxes: xAxes} as Visualization]))
          break;
        }
        case 'y': {
          let yAxes = [...(vis?.yAxes || [])]
          yAxes.splice(axisIndex[1], 1)
          dispatch(updateVis([group.id, {...vis, yAxes: yAxes} as Visualization]))
          break;
        }
      } 
    }   
  }

  
  /**
  * Switch between stacked and grouped
  */
  const axisComposeType = (axisId) => {
    const axisIndex = getAxisIndexById(axisId)
    let axis: any
    if (axisIndex) {
        switch (axisIndex[0]) {
        case 'x': {
          const xAxes = [...(vis?.xAxes || [])]
          axis = xAxes[axisIndex[1]]

          break;
        }
        case 'y': {
          const yAxes = [...(vis?.yAxes || [])]
          axis = yAxes[axisIndex[1]]
          break;
        }
      }
    }
    if(axis){
      if(axis.axisConfig.stacked === true){
        return "stack"
      } else if (axis.axisConfig.grouped === true){
        return "group"
      } else {
        return ''
      }
    } else {
      return ''
    }
   
  }

  /**
  * Generic updater for an Axis Property
  */
  const axisPropsUpdater = (axisId: string, props: any) => {
    const axisIndex = getAxisIndexById(axisId)
    if (axisIndex && group?.id) {
        switch (axisIndex[0]) {
        case 'x': {
          let xAxes = [...(vis?.xAxes || [])]
          xAxes[axisIndex[1]] = {...xAxes[axisIndex[1]], ...props}
          dispatch(updateVis([group.id, {...vis, xAxes: xAxes} as Visualization]))
          break;
        }
        case 'y': {
          let yAxes = [...(vis?.yAxes || [])]
          yAxes[axisIndex[1]] = {...yAxes[axisIndex[1]], ...props}
          dispatch(updateVis([group.id, {...vis, yAxes: yAxes} as Visualization]))
          break;
        }
      }
    }
  }

  /**
  * Remove a Series
  */
  const seriesRemover = (seriesId: string) => {
    if(group?.id) {
      dispatch(updateVis([group.id, {...vis, series: vis.series.filter(s => s.id !== seriesId)}] ))
    }
  }


  const axisConfigRenderer = (axis: Axis) => {

    return (
      <div className="axis-config">
        <Row>
          <Col>
            <Label>Axis Name
              <input className={Classes.INPUT + " bp4-fill"} type="text" value={axis.name || ''} onChange={(event: any) => axisNameChanger(event, axis)}/>
            </Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Min
              <NumericInput value={axis.axisConfig.min || 0} fill={true} onValueChange={(value: number, stringValue, __) => axisMinChanger(value, stringValue, axis)}/>
            </Label>
          </Col>
          <Col>
            <Label>Max
              <NumericInput value={axis.axisConfig.max || 0} fill={true} onValueChange={(value: number, stringValue, __) => axisMaxChanger(value, stringValue,  axis)}/>
            </Label>
          </Col>
          <Col>
            <Label>MultiSeries Composition
              <HTMLSelect options={[{label: 'Stacked', value: 'stack'}, {label: 'Grouped', value: 'group'}, {label: 'Default', value: ''}]}
                          value={axisComposeType(axis.id)}
                          onChange={(event) => axisComposerChanger(event, axis) }
              />
                          
            </Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Label
              <Checkbox checked={axis.axisConfig.title?.display || false } label="Enabled" onChange={(event) => {axisTitleEnabledChanger(event, axis)}}/>
              <input className={Classes.INPUT + " bp4-fill"} type="text" value={axis.axisConfig.title?.text || ''} onChange={(event) => axisTitleChanger(event, axis)}/>  
            </Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>
              Position
              <HTMLSelect options={Object.keys(PositionOption)} value={axis.axisConfig.position || "default" } onChange={(event) => axisPositionChanger(event, axis)} />
            </Label>
          </Col>
          <Col>
            <Label>Axis Type
              <HTMLSelect options={Object.keys(AxisTypeOption)} value={axis.axisConfig.type || "default" } onChange={(event) => axisTypeChanger(event, axis)} />

            </Label>
            { axis.axisConfig.type === "time" &&
               <HTMLSelect options={Object.keys(AxisTimeUnits)} value={axis.axisConfig.time?.unit || "default" } onChange={(event) => axisTimeUnitChanger(event, axis)} />
             
            }
          </Col>
        </Row>
        <Row>
          <Col>
            <Button icon="trash" intent="danger" minimal={true} text="Remove" onClick={() => removeAxis(axis)} />
          </Col>
        </Row>
      </div>
    );
  }

  const updateSortBy = (event) => {
    if (group?.id) {
      dispatch(updateVis([group.id, {...vis, sortBy: event.target.value} as Visualization]))
    }
  }
  const updateOrderBy = (event) => {
    if (group?.id) {
      dispatch(updateVis([group.id, {...vis, orderBy: event.target.value} as Visualization]))
    }
  }

  const setConcatenation = (event) => {
    if (group?.id) {
      dispatch(updateVis([group.id, {...vis, concatenate: event.target.checked } as Visualization]))
    }
 }

  const createSeries = () => {
    const newSeries = {
      id: v4(),
      reportDataId: '',
      dataSetId: '',
      borderWidth: 1      
    }
    if (group?.id && vis) {
      dispatch(updateVis([group.id, {...vis, series: [...vis.series, newSeries]}]))
    }
  
  }

  function isReport(obj: Dashboard|Report|undefined|null): obj is Report {
    return (obj as Report).reportData !== undefined
  }

  const dataSortOptions = () => {
    let options:{value: number, label: string}[] = []
    if (group && isReport(group) && vis ) {
      vis.series.forEach((series, index) => {
        const reportData = (group as Report).reportData.find(rd => rd.id === series.reportDataId)
        if (reportData && reportData.datasets) {
          const dataset = reportData.datasets.find(ds => ds.id === series.dataSetId)
          if (dataset) {
            options.push({value: index, label: dataset.label})
          }
        }
      })
    }
    return options
  }

  const general = () => {

    return (
      <Card>
        <h4 className="bp4-heading">General</h4> 
        <Label>Title
          <input className={Classes.INPUT + " bp4-fill"} type="text" placeholder="Title..." dir="auto" value={vis.title || ''} onChange={titleChanger}/>
        </Label>
        <Label>
          Type
          <HTMLSelect options={Object.keys(VisType)} value={vis.chartType} minimal={true} onChange={typeChanger} />
        </Label>
      </Card>

    )
  }

  const axes = () => {
    return (
      <Card>
        <h4 className="bp4-heading">Axes</h4>
        <h5>X Axes </h5>
        { vis.xAxes.map( axis => 
          <div>

            { axisConfigRenderer(axis) }
          </div>
        )}
        <Button text="+" onClick={() => createAxis('x')} />
        <h5>Y Axes</h5>        { vis.yAxes.map( axis =>
          <div> 
            { axisConfigRenderer(axis) }
          </div>
        )} 
        <Button text="+" onClick={() => createAxis('y')} />
      </Card>

    )
  }

  const seriesStep = () => {
    return(
      <Card>
        <h4 className="bp4-heading">Data Series</h4>
        <Label>
          Series
        </Label>
          <Checkbox checked={vis.concatenate || false } label="Concatenate" onChange={setConcatenation}/>      
          { vis.series.map((series, index) =>
            <div>
              <Row>
                <Col xs="auto">
                  <ButtonGroup>
                    <Button alignText="left" minimal={false} small={true}  onClick={() => toggleSeriesConf(index)} icon="cog" />
                    <Button minimal={false} small={true} icon="trash" intent="danger" onClick={() => seriesRemover(series.id)} />
                  </ButtonGroup>
                </Col>
                <Col>  
                  <SeriesTag series={series} />
                </Col>

              </Row>
              
              { seriesOpen[index] &&
              <SeriesConfigRenderer2
                seriesConfigId={series.id}
                visualization={vis}
                groupType='Dashboard'
              />
              }
            </div>
          )}
          <Button text="+" onClick={createSeries} />
          <Divider />
          <Label>
          Sort
          </Label>
          <Row>
            <Col>
              <HTMLSelect 
                options={[{label: "Select", value: -1}].concat(dataSortOptions())} 
                value={vis.sortBy || undefined }
                onChange={updateSortBy}

              />
            </Col>
            <Col>
              <HTMLSelect
                options={['ASC', 'DESC']}
                value={vis.orderBy || 'ASC'}
                onChange={updateOrderBy}
              /> 
            </Col>
          </Row>
      </Card>

    )
  }

  return (
    <div>
      <Button onClick={() => setIsOpen(true)} icon="cog">Edit</Button>
      { group && vis &&
      <div>
      <MultistepDialog isOpen={isOpen} onClose={() => setIsOpen(false)} title="Visualization Settings" isCloseButtonShown={true} finalButtonProps={{text: 'Close', onClick: () => setIsOpen(false)}}>
        <DialogStep title="General" panel={general()} id="general" />
        <DialogStep title="Axes" panel={axes()} id="axes" />
        <DialogStep title="Series" panel={seriesStep()} id="series" />
      </MultistepDialog>

      </div>
      }
    </div>
  );
}
