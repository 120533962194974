import React, { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { allLocations as allLocationsSelector, makeSelectLocationsByPath, loadLocations } from '../features/locationsSlice'
import { mySelectedOrg} from '../features/orgsSlice';

import { useLsQuery, useLazyGetByUUIDQuery } from '../services/locationApi'
import { Spinner, Checkbox, ButtonGroup, Button } from "@blueprintjs/core";
import { LocationNode } from '../types/LocationNode'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { SelectionEvent } from './LocationBrowser'
import { LocationItem } from './LocationItem'

export const LocationPath = ({path, handleBrowse, handleSelection, parentNode, selectedPaths, altPath }:{path: string, handleBrowse: any, handleSelection: any, parentNode:LocationNode|undefined, selectedPaths: string[], altPath:string}) => {
  const dispatch = useAppDispatch()
  const selector = makeSelectLocationsByPath()
  const locations = useAppSelector((state) => selector(state, path))
const myOrg = useAppSelector(mySelectedOrg)
  const allLocations = useAppSelector(allLocationsSelector)

  let [browsed, setBrowsed] = useState<null|LocationNode>(null)
  
  let [getByUUID, uuidLocationsResult, lastPromise] = useLazyGetByUUIDQuery()

  const {data: locationsData, error: locationsError, isLoading: locationsIsLoading} = useLsQuery(path)

  useEffect(() => {
    if (locationsData) {
      dispatch(loadLocations(locationsData))
      locationsData.forEach(loc => {
        if (loc.OriginalUUID !== undefined) {
          getByUUID(loc.OriginalUUID)
        }
      })
    }

  }, [locationsData, locationsError, locationsIsLoading, getByUUID])
 
  useEffect(() => {
    if(uuidLocationsResult?.data) {
      dispatch(loadLocations(uuidLocationsResult.data))
    }
    
  }, [uuidLocationsResult])

  const clickHandler = (location: LocationNode) => {
    // is this a link
    //location = getOriginal(location)
    if (getOriginal(location) === location) {
      handleBrowse(location)
      setBrowsed(location)
    }
  }

  const getOriginal = (location: LocationNode):LocationNode => {
    if (location.OriginalUUID !== undefined ){
      const original = allLocations.find(loc => loc.UUID == location.OriginalUUID)
      if (original) {
        return original
      } else {
        return location
      }
    } else {
      return location
    }
  }

  const handleCheckChange = (event:any, location: LocationNode, selectChildren: boolean) => {
    const oLocation = getOriginal(location)
    let oPath = `${oLocation.Path}${oLocation.Name}`
    oPath += selectChildren ? '/*' : '/'
    let path = `${location.Path}${location.Name}`
    path += selectChildren ? '/*' : '/'

    const s: SelectionEvent = {
      select: (event.target.checked),
      path: oPath,
      symPath: path
    }
    handleSelection(s)
  }

  const isIndeterminatePath = (path: string):boolean => {
    let ind = false
    let testPath = path.replace('*', '')
    selectedPaths.every(sPath => {
      ind = (sPath.indexOf(testPath) == 0)
      if (ind) {
        return false
      } else {
        return true
      }
    })
    return ind

  }

  const isIndeterminate = (location: LocationNode, selectChildren: boolean) => {
    if (isChecked(location, selectChildren)){
      return false;
    }
    const original = getOriginal(location)
    let testPath = `${location.Path}${location.Name}`
    testPath += selectChildren ? '/*' : '/'
    let oPath = `${original.Path}${original.Name}`
    oPath += selectChildren ? '/*' : '/'

    return isIndeterminatePath(testPath) || isIndeterminatePath(oPath) || isIndeterminatePath(testPath.replace(path, altPath)) 
  }

  const isCheckedPath = (path: string):boolean => {
    let checked = false
    selectedPaths.every(sPath => {
      const [primary, wildcard] = sPath.split('*')
      if (wildcard == ''){
        if(path.indexOf('*') >= 0){
          checked = (path == sPath)
        }
        // this is a wild card path
        checked = (path.indexOf(primary) == 0)
      } else {
        checked = (path == primary)
      }
      if (checked == true) {
        return false
      } else {
        return true
      }
    })
    return checked
  }
  const locationLicensed  = (location: LocationNode):boolean => {
    if (myOrg && myOrg.org.hasOwnProperty('licensedpaths')) {
      let value = false
      myOrg.org.licensedpaths?.forEach(path => {
        if ((location.Path + location.Name).indexOf(path) == 0)
          value = true;
      })
      return value;
    } else {
      return false;
    }
  }

  const isChecked = (location: LocationNode, selectChildren: boolean) => {
    const original = getOriginal(location)
    let testPath = `${location.Path}${location.Name}`
    testPath += selectChildren ? '/*' : '/'
    let oPath = `${original.Path}${original.Name}`
    oPath += selectChildren ? '/*' : '/'
//    console.log({t: testPath, altPath: altPath, p: path})
    return isCheckedPath(testPath) || isCheckedPath(oPath) || isCheckedPath(testPath.replace(path, altPath))
  }

  return (
    <div className="location-browser-path">
      { locationsIsLoading && 
        <Spinner />
      }
      <ButtonGroup minimal={true} vertical={true} >
        { locations?.length > 0  && parentNode &&
           <div className={'location-item'} >
              <Checkbox 
                onChange={(event) => handleCheckChange(event, parentNode, false)}
                checked={isChecked(parentNode, false)}
              />
              <Button minimal={true}  alignText="left">
                <LocationItem location={parentNode} includeChildren={false}/>
              </Button>
          </div>
         
        }

        { myOrg && locations?.filter(l => locationLicensed(l)).map((location:LocationNode) =>
          <div className={'location-item'} >
              <Checkbox 
                onChange={(event) => handleCheckChange(event, location, true)}
                checked={isChecked(location, true)}
                indeterminate={isIndeterminate(location, true)}
              />
              <Button 
                className="location-button"
                icon={location.OriginalUUID ? "nest" : null} 
                minimal={true}
                onClick={() => clickHandler(location)} 
                active={location.UUID == browsed?.UUID} 
                alignText="left" 
                rightIcon={location.UUID == browsed?.UUID || location.OriginalUUID == browsed?.UUID ? 'chevron-right' : null}
                ><LocationItem location={location} includeChildren={true}/></Button>
          </div>
        )}
      </ButtonGroup>
    </div>
  
  )
}
