import  React  from 'react';
import { Breadcrumb, BreadcrumbProps } from "@blueprintjs/core";
import { Link } from "react-router-dom";
import { Breadcrumbs2 } from "@blueprintjs/popover2";

export const Breadcrumbs = ({breadcrumbs}:{breadcrumbs: BreadcrumbProps[]}) => {

  const breadCrumbRenderer = ({ text, href, ...restProps }: BreadcrumbProps) => {
    return (
      <Breadcrumb {...restProps}>
        {href &&
        <Link to={href}>{text}</Link>
        }
      </Breadcrumb>
    );
  }

  const currentBreadcrumbRenderer =  ({ text, href, ...restProps }: BreadcrumbProps) => {
    return (
      <Breadcrumb className="bp4-breadcrumb-current" {...restProps}>
        {href &&
        <Link to={href}>{text}</Link>
        }
      </Breadcrumb>
    );
  }

  return (
    <Breadcrumbs2 items={breadcrumbs} breadcrumbRenderer={breadCrumbRenderer} currentBreadcrumbRenderer={currentBreadcrumbRenderer}/>
  )

}
