import  React, {useEffect}  from 'react';

import { Card, Callout } from "@blueprintjs/core";
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { updateDashboardArtifactSelections, selectedDashboard, makeVisualizationSelector } from '../features/dashboardSlice'
import { useLazyGetDataSetQuery } from '../services/dataApi'
import { loadDataSets, makeDataSetSelector } from '../features/dashboardDataSlice'
import { Visualizer2 } from './Visualizer2'
import { VisualizationConfigurator2 } from './VisualizationConfigurator2'
import { DashboardData } from '../types/DashboardData'
import { SelectionViewer2 } from './SelectionViewer2' 

/*
The Dashboard Panel is responible for making sure the data for the visuzliation is available and displaying the Vizualization2 component.

*/
export const DashboardPanel = ({visualizationId}:{visualizationId:string}) => {


  const selector = makeVisualizationSelector()
  const visualization = useAppSelector(state => selector(state, visualizationId))

  const [ triggerGetDataSet, resultGetDataSet, lastGetDataSet] = useLazyGetDataSetQuery();

  const dataSets: any[] = []

  const dataSelector = makeDataSetSelector()
  const data = useAppSelector(state => dataSelector(state, (visualization?.series || [])))

  const dispatch = useAppDispatch()
   
  const dashboard = useAppSelector(selectedDashboard);
  useEffect(()=> {
    //if(data.length !== visualization?.series.length && typeof lastGetDataSet.lastArg !== 'object') {
      const results: any[] = []
      visualization?.series.forEach(ser => {
        results.push( triggerGetDataSet([ser.reportId || '', ser.dataSegmentIndex || 0, ser.reportDataId || ''], true))
      })
      Promise.all(results).then((responses) => {
        responses.forEach(result => {

          if (result['status'] == 'fulfilled'){
            dispatch(loadDataSets([result['data'] as DashboardData]))
          } else {}
        })
      })
    //}
  }, [visualization])

  const handleSelect = (selections) => {
    if(dashboard){
      dispatch(updateDashboardArtifactSelections([dashboard.id, selections]))
    }
  }

  const selections = () => {
    if(dashboard) {
      return dashboard.artifactSelections?.filter(sel => sel.visualizationUUID == visualization?.id) || []
    } else {
      return []
    }

  }


  return (
    <Callout className="dashboard-panel">
    { visualization &&
      <div>
      <Visualizer2 visualization={visualization} groupType="dashboards" onSelect={handleSelect}/>
      { selections().map((selection) => 
        <SelectionViewer2 selection={selection} groupType="dashboards" />      
      )}
      </div>
    }
    </Callout>

  )

}
