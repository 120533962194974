import  React, { useEffect }  from 'react';
import { Classes, MenuItem, Card, Label, Button, ButtonGroup, Divider, Collapse } from "@blueprintjs/core";
import { Select, ItemRenderer, ItemPredicate } from "@blueprintjs/select";
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { load, loadData, select, selectedReport, selectReports } from '../features/reportsSlice';
import { Report } from '../types/Report'
import { useLazyGetDataQuery } from '../services/reportApi'

const ReportSelect = Select.ofType<Report>()


export const ReportSelector = ({handleSelect= null}:{handleSelect?:any|null}) => {
  
  const dispatch = useAppDispatch();
  const report = useAppSelector(selectedReport);
  const reports = useAppSelector(selectReports);

  const [ getReportData, reportDataResponse, lastPromiseInfo ] = useLazyGetDataQuery()
  const reportSelect = (item: Report, event?: React.SyntheticEvent<HTMLElement>) => {
    dispatch(select(item.id))
    if(typeof handleSelect == 'function') {
      handleSelect(item.id)
    }
  }



  const reportRenderer: ItemRenderer<Report> = (aType, { handleClick, modifiers }) => {
    return (
        <MenuItem
          active={modifiers.active}
          key={aType.id}
          text={aType.config.name}
          onClick={handleClick}
        />
    );
  }

  const reportPredicate: ItemPredicate<Report> = (query: string, item: Report, index?: undefined | number, exactMatch?: undefined | false | true): boolean => {
    if (item.config.name.toLowerCase().indexOf(query.toLowerCase()) > -1) {
      return true
    } else {
      return false
    }
  }

  return (
    <div>
      { reports &&
        <ReportSelect
          items={reports}
          itemRenderer={reportRenderer}
          onItemSelect={reportSelect}
          itemPredicate={reportPredicate}
        >
          <Button text={ report ?report.config.name : 'Select A Data Filter'} rightIcon="double-caret-vertical" />
        </ReportSelect> 
      }
    </div>
  );
}
