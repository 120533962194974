import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query'
import { featureConfig } from '../core/featureConfig' 


// Slices
import locationsReducer from '../core/features/locationsSlice'
import artifactsReducer from '../core/features/artifactsSlice'
import dashboardDataReducer from '../core/features/dashboardDataSlice'
import userReducer from '../core/features/userSlice'
import reportOptionsReducer from '../core/features/reportOptionsSlice'
import reportsDataReducer from '../core/features/reportsSlice'
import orgsReducer from '../core/features/orgsSlice'
import dashboardsReducer from '../core/features/dashboardSlice'


// APIs
import { locationApi } from '../core/services/locationApi'
import { reportApi } from '../core/services/reportApi'
import { usersOrgsApi } from '../core/services/usersOrgsApi'


// ...

export const store = configureStore({
  reducer: {
    userData: userReducer,
    reportOptions: reportOptionsReducer,
    reportsData: reportsDataReducer,
    orgsData: orgsReducer,
    locationsData: locationsReducer,
    artifactsData: artifactsReducer,
    dashboardData: dashboardDataReducer,
    dashboards: dashboardsReducer,
    [reportApi.reducerPath]: reportApi.reducer,
    [usersOrgsApi.reducerPath]: usersOrgsApi.reducer,
    [locationApi.reducerPath]: locationApi.reducer,


  },
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware().concat(reportApi.middleware).concat(usersOrgsApi.middleware), 
  devTools: featureConfig.reduxDevToolsEnabled
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
setupListeners(store.dispatch)
