import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { makeSelectedArtifactsForVis } from '../features/artifactsSlice'
import { SelectionModes, ColumnHeaderCell2, Cell, Column, Table2 } from "@blueprintjs/table";
import {useLazyGetArtifactsByDateRangeQuery}  from '../services/reportApi'
import { loadArtifacts } from '../features/artifactsSlice'
import { updateReportArtifactSelectionChecksums, selectSearchQueries, selectedReportId } from '../features/reportsSlice';
import { featureConfig } from '../featureConfig'
import { Spinner, Card, Menu, MenuItem } from "@blueprintjs/core";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Artifact } from '../types/Artifact'


export const SelectionViewer = ({selection}) => {
  const dispatch = useAppDispatch()
  const reportId = useAppSelector(selectedReportId)

  const artifactsSelector = makeSelectedArtifactsForVis();

  const selectedArtifacts = useAppSelector((state) => artifactsSelector(state, selection))
  const searchQueries = useAppSelector(selectSearchQueries)

  const [getSelectedArtifacts, apiSelectedArtifacts, lastSelectedPromise] = useLazyGetArtifactsByDateRangeQuery()

  const [sortMap, setSortMap] = useState<number[]>([])

  const user = useAppSelector((state) => state.userData.user)

  const headers:{name: string, element: string}[] = useMemo(() => {
    return [
      {name: "Headline", element: "title"},
      {name: "URL", element: "url"},
      {name: "Publish Date", element: "published"},
      {name: "Sentiment", element: "sentiment"},
      {name: "Political Score", element: "political_score"},
      {name: "Sadness", element: "sadness"},
      {name: "Anger", element: "anger"},
      {name: "Fear", element: "fear"},
      {name: "Joy", element: "joy"},
      {name: featureConfig.surpriseLabels[user?.filterModule || 0], element: "surprise"},
    ]
  }, [])

  const clearSortMap = useCallback(() => {
    setSortMap([])
  }, [setSortMap])

  const genSortMap = (index, direction) => {
    if (selectedArtifacts?.length > 0) {
      const artifacts = [...selectedArtifacts]
      

      const checksumOrder = artifacts.sort((a,b) => {
        let val1 = a[headers[index].element]
        let val2 = b[headers[index].element]

        if (direction !== 'ASC') {
          const temp = val1
          val1 = val2
          val2 = temp
        }
        if (val1 < val2) {
          return -1
        } else if (val2 < val1){
          return 1
        } else if (val1 == val2) {
          return 0
        } else {
          return 0
        }

      }).map(x => x.checksum)

      let sortOrder:number[] = []
      checksumOrder.forEach(checksum => {
        sortOrder.push(selectedArtifacts.findIndex(art => art.checksum === checksum))
      })
      setSortMap(sortOrder)
    }
  }//, [selectedArtifacts, headers])

  useEffect( () => {
    if(reportId && selection.checksums.length < 1) {
      getSelectedArtifacts([reportId, selection.uuid, selection.start / 1000, selection.end / 1000])
    }
  }, [getSelectedArtifacts, selection, reportId])

  useEffect(() => {
    if ( apiSelectedArtifacts ) {
      const { originalArgs, data, status } = apiSelectedArtifacts
      if(status == "fulfilled" && originalArgs && data) { 
        dispatch(loadArtifacts([originalArgs[1], data]))
        let checksums = data.map(artifact => artifact.checksum)
        dispatch(updateReportArtifactSelectionChecksums([originalArgs[0], originalArgs[1], checksums]))
      }
    }
  }, [apiSelectedArtifacts, lastSelectedPromise, dispatch])

  useEffect(() => {clearSortMap()}, [selection, clearSortMap])


  const urlRenderer = (rowIndex: number, colIndex: number) => {
    const data = sortedDataCell(rowIndex, colIndex)
    return (
      <Cell>
        <a href={data} target="_blank">
          {data}
        </a>
      </Cell>
    )
  }

  const dateRenderer = (rowIndex: number, colIndex: number) => {
    const date = new Date(sortedDataCell(rowIndex, colIndex))
    return (
      <Cell>{date.toDateString()}</Cell>
    )
  }


  const cellRenderer = (rowIndex: number, colIndex: number) => {
    return (
      <Cell>{sortedDataCell(rowIndex, colIndex)}</Cell>
    )
  }

  const sortedDataCell = (rowIndex: number, colIndex: number):any => {
    let row: number = sortMap?.at(rowIndex) === undefined ? rowIndex : sortMap?.at(rowIndex) as number
    let element: Artifact|undefined = selectedArtifacts.at(row)
    let value = element?.[headers[colIndex].element]
      if (value === null || value === undefined){
        value = ''
      }
    return value
  }

  const sort = (index: number, direction:'ASC'|'DESC') => {
    clearSortMap()
    genSortMap(index, direction) 
  }


  const menuRenderer = (index: number) => {
    return (
      <Menu>
        <MenuItem text="Sort Asc" onClick={() => sort(index, 'ASC')}/>
        <MenuItem text="Sort Desc" onClick={() => sort(index, 'DESC')} />
      </Menu>
    )
  }

  const headerRenderer = (index: number) => {
    return (
      <div>
      { user &&
      <ColumnHeaderCell2 
        menuIcon="chevron-down"
        name={headers[index].name}
        menuRenderer={() => menuRenderer(index)}
      />
      }
      </div>
    )
  }

  const queryTitle = () => {
    let sq = searchQueries?.find(sq => selection.uuid === sq.id) || null
    return sq?.name || 'Data'
  }

  const loading = ():boolean => {
    return !(apiSelectedArtifacts?.status == 'fulfilled' && selectedArtifacts && selectedArtifacts?.length >= 0)
  }

  return (
  <Row>
    <Col sm={12}>
    <Card className="selection-viewer">
      <h3>{queryTitle()}</h3>
      {  loading() && 
        <Spinner />
      }
      { !loading() && selectedArtifacts?.length > 0 &&
        <Table2 numRows={selectedArtifacts?.length} className="article-selection-table" cellRendererDependencies={[sortMap]} selectionModes={SelectionModes.NONE}>
          <Column columnHeaderCellRenderer={headerRenderer} cellRenderer={cellRenderer} />
          <Column columnHeaderCellRenderer={headerRenderer} cellRenderer={urlRenderer} />
          <Column columnHeaderCellRenderer={headerRenderer} cellRenderer={dateRenderer} />
          <Column columnHeaderCellRenderer={headerRenderer} cellRenderer={cellRenderer} />
          <Column columnHeaderCellRenderer={headerRenderer} cellRenderer={cellRenderer} />
          <Column columnHeaderCellRenderer={headerRenderer} cellRenderer={cellRenderer} />
          <Column columnHeaderCellRenderer={headerRenderer} cellRenderer={cellRenderer} />
          <Column columnHeaderCellRenderer={headerRenderer} cellRenderer={cellRenderer} />
          <Column columnHeaderCellRenderer={headerRenderer} cellRenderer={cellRenderer} />
          <Column columnHeaderCellRenderer={headerRenderer} cellRenderer={cellRenderer} />
        </Table2>
      }
      { !loading() && selectedArtifacts?.length == 0 && apiSelectedArtifacts?.data?.length == 0 &&
        <div>
          <h4>No Artifacts Found - Try broadening your selection.</h4>
          <p>If this is an old report, you may need to be rerun it.</p>
        </div>
      }
    </Card>
    </Col>
  </Row>
  )

}
