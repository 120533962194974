import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Report } from '../types/Report'
import { ReportData } from '../types/ReportData'
import { ArtifactType } from '../types/ArtifactType'
import { Artifact } from '../types/Artifact'
import { Location } from '../types/Location' 
import { serviceConfig } from './serviceConfig'
import { InternalUser } from '../types/InternalUser'
import type { RootState } from '../../app/store'
import { SearchQuery } from '../types/SearchQuery'
import { SearchQueryUsage } from '../types/SearchQueryUsage'
import { LocationSourceCount } from '../types/LocationSourceCount'
import { selectUser } from '../features/userSlice';

import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'

type ReportsResponse = Report[]

const rawBaseQuery = (baseUrl: string) => fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, {getState}) => {
    const token = (getState() as RootState).userData.user?.jwt || ''
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },

})

const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const user = selectUser(api.getState() as RootState)

  if (  user?.filterModule == undefined || user?.filterModule == null) {
    console.log('Error in Dyn URL')
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No project ID received',
      },
    }
  }

  // construct a dynamically generated portion of the url
  const baseUrl = serviceConfig.reportApis[user.filterModule]
  // provide the amended url and other params to the raw base query
  return rawBaseQuery(baseUrl)(args, api, extraOptions)
}

export const reportApi = createApi({
  reducerPath: 'reportApi',
  tagTypes: ['Reports', 'Data', 'ReportShares', 'SearchQueries', 'SearchQueryUsages', 'NONE',
    'DataIndex', 'DataSegmentIndex', 'Segment', 'DataSet', 'Dashboard', 'DashboardShares'
  ],
  baseQuery: dynamicBaseQuery,

  endpoints: (builder) => ({

    // Base Report - No Data
    getReport: builder.query<Report, string>({
      query: (uuid) => `reports/${uuid}`,
      providesTags: (result) =>
        result? [{type: 'Reports', id: result.id}] : [],
    }),

    // Data for a report
    getData: builder.query<ReportData, string>({
      query: (uuid) => `reports/${uuid}/data`,
      providesTags: (result) =>
        result? [{type: 'Data', id: result.id}] : [],
    }),

    // Get all reports for user
    getMyReports: builder.query<ReportsResponse, null>({
      query: () => 'reports/',
      providesTags: (result) =>
        result && result.length > 0 ? result.map(({id}) => ({type: 'Reports', id})) : ['Reports']
    }),

    // Get Options for a report (Artifact Types and Locations
    getReportOptions: builder.query<{locations: Location[], artifact_types: ArtifactType[]}, null>({
      query: () => 'reports/options',
    }),

    // Get Options for a report (Artifact Types and Locations
    getReportSourceCount: builder.query<LocationSourceCount, string>({
      query: (location_path) => `reports/source_count?location_path=${encodeURIComponent(location_path)}`,
    }),



    // Create a new report
    createReport: builder.mutation<Report, Partial<Report>>({
      query: (body) => ({
        url: `reports`,
        method: 'POST',
        body: {report: body}
      }),
      invalidatesTags: ['Reports']
    }),

    // Copy a report
    copyReport: builder.mutation<Report, Partial<Report>>({
      query: (body) => ({
        url: `reports/${body.id}/copy_report`,
        method: 'POST',
        body: {report: body}
      }),
      invalidatesTags: (result, error, arg) => {
        return result ?
          [{ type: 'Reports'}]
        :
          []
      }
    }),

    // Uodate / Save a report
    updateReport: builder.mutation<Partial<Report>, Report>({
      query: (body) => ({
        url: `reports/${body.id}`,
        method: 'PUT',
        body: {report: {...body, reportData: []}}
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Reports', id: arg.id }]
    }),
    runReport: builder.mutation<Report, Partial<Report>>({
      query: (body) => ({
        url: `reports/${body.id}/run`,
        method: 'PUT'
      }),
    }),
    stopReport: builder.mutation<Report, Partial<Report>>({
      query: (body) => ({
        url: `reports/${body.id}/stop`,
        method: 'PUT'
      }),
    }),
    retryRunReport: builder.mutation<Report, Partial<Report>>({
      query: (body) => ({
        url: `reports/${body.id}/retry_run`,
        method: 'PUT'
      }),
    }),
    // Get Report Running Status
    getDataStatus: builder.query<Partial<Report>, string>({
      query: (id) => ({
        url: `reports/${id}/data_status`,
        method: 'GET'
      }),
    }),

    // Get information about how the report is shared
    getShares: builder.query<{users: string[], success?: boolean}, ['reports'|'dashboards', string]> ({
      query: (args) => ({
        url: `${args[0]}/${args[1]}/shares`,
        method: 'GET'
      }),
      providesTags: (result, error, args) => {
        if (!error) {
          switch(args[0]){
            case 'reports':
              return [{ type: 'ReportShares', id: args[1] }]
              break;
            case 'dashboards':
              return  [{ type: 'DashboardShares', id: args[1] }]
          }
        } else {
          return ['NONE']
        }
      },
    }),

    // Share the report with another user (by uuid)
    addShare: builder.mutation<{success: boolean}, ['reports'|'dashboards', string, string]> ({
      query: (q) => ({
        url: `${q[0]}/${q[1]}/shares`,
        method: 'POST',
        body: {user: {uuid: q[2]}}
      }),
      invalidatesTags: (result, error, args) => {
        if (!error) {
          switch(args[0]){
            case 'reports':
              return [{ type: 'ReportShares', id: args[1] }]
              break;
            case 'dashboards':
              return  [{ type: 'DashboardShares', id: args[1] },
                        {type: 'ReportShares'}]

          }
        } else {
          return ['NONE']
        }


      },
    }),

    // Unshare a report
    removeShare: builder.mutation<{success: boolean}, ['reports'|'dashboards',string, string]> ({
      query: (q) => ({
        url: `${q[0]}/${q[1]}/shares/${q[2]}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, args) => {
        if (!error) {
          switch(args[0]){
            case 'reports':
              return [{ type: 'ReportShares', id: args[1] }]
              break;
            case 'dashboards':
              return  [{ type: 'DashboardShares', id: args[1] },
                        {type: 'ReportShares'}]
          }
        } else {
          return ['NONE']
        }


      },
    }),
  
    // get all avaiable Search Queries
    getSearchQueries: builder.query<SearchQuery[], string>({
      query: (id) => ({
        url: `reports/${id}/search_queries`,
        method: 'GET'
      }),
      providesTags: (result, error, arg) => {
        if (!error) {
          return result && result.length > 0 ? result.map((r) => ({ type: 'SearchQueries', id: r.id })) : ['NONE']
        } else {
          return ['NONE']
        }
      },
    }),

    createSearchQuery: builder.mutation<Partial<SearchQuery>, [string, SearchQuery]>({
      query: (body) => ({
        url: `reports/${body[0]}/search_queries`,
        method: 'POST',
        body: body[1]
      }),
      invalidatesTags: ['SearchQueries']
    }),
   
    updateSearchQuery: builder.mutation<Partial<SearchQuery>, [string, SearchQuery]>({
      query: (body) => ({
        url: `reports/${body[0]}/search_queries/${body[1].id}`,
        method: 'PUT',
        body: body[1]
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'SearchQueries', id: arg[1].id}]
    }),
   

    // get currently used queries
    getSearchQueryUsages: builder.query<SearchQueryUsage[], string>({
      query: (id) => ({
        url: `reports/${id}/search_query_usages`,
        method: 'GET'
      }),
      providesTags: (result, error, arg) => {
        if (!error) {
          return [{type: 'SearchQueryUsages', id: arg}]
        } else {
          return ['NONE']
        }
      },
    }),

    updateSearchQueryUsages: builder.mutation<{success: boolean}, [string, string[]]>({
      query: (args) => ({
        url: `reports/${args[0]}/search_query_usages/update`,
        method: 'PUT',
        body: {search_query_usages: args[1] }
      }),
      invalidatesTags: (result, error, args) =>{
        return [{type: 'SearchQueryUsages', id: args[0]}]
      }
    }),

    // get artifacts by date range and query
    getArtifactsByDateRange: builder.query<Artifact[], [string, string, number, number]>({
      query: (args) => ({
        url: `reports/${args[0]}/artifacts?query_uuid=${args[1]}&start_date=${args[2]}&end_date=${args[3]}`,
        method: 'GET'
      }),
    }),

    // Delete a report
    deleteReport: builder.mutation<Report, Partial<Report>>({
      query: (body) => ({
        url: `reports/${body.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Reports']
    }),
  }),


});

export const { useLazyGetReportSourceCountQuery, useUpdateSearchQueryUsagesMutation, useUpdateSearchQueryMutation, useCreateSearchQueryMutation, useLazyGetSearchQueryUsagesQuery, useLazyGetSearchQueriesQuery, useAddShareMutation, useRemoveShareMutation, useGetSharesQuery, useLazyGetDataQuery, useCopyReportMutation, useLazyGetDataStatusQuery, useLazyGetReportQuery, useGetDataStatusQuery, useRunReportMutation, useStopReportMutation, useGetReportOptionsQuery, useDeleteReportMutation, useUpdateReportMutation, useCreateReportMutation, useGetReportQuery, useGetMyReportsQuery, useLazyGetArtifactsByDateRangeQuery, useRetryRunReportMutation } = reportApi
