import  React, {useState, useEffect, useContext}  from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { removeVis, createVis, selectedReport, selectedVis, selectVis, selectedVisualizations, selectedArtifactSelections, selectedReportId } from '../features/reportsSlice';

import { Label, Menu, MenuItem, Drawer, Button, Tab, Tabs } from "@blueprintjs/core";
import { Classes, Popover2 } from "@blueprintjs/popover2";

import { Visualizer } from './Visualizer';
import { Visualization } from '../types/Visualization'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import { VisualizationConfigurator } from './VisualizationConfigurator';
import { SelectionViewer } from './SelectionViewer'

import { DarkModeContext } from '../../App'



export const TabbedVisualizer = () => {

  const reportId = useAppSelector(selectedReportId)

  const vis = useAppSelector(selectedVis)
  const visualizations = useAppSelector(selectedVisualizations)
  const {darkModeState, setDarkModeState} = useContext(DarkModeContext)

  const artifactSelections = useAppSelector(selectedArtifactSelections);

  const dispatch = useAppDispatch()



  useEffect(() => {
    if(visualizations){
      if (vis) {
        if (visualizations.find(v => v.id == vis.id) == undefined) {
          dispatch(selectVis(visualizations[0].id))
        }
      } else if (visualizations.length > 0) {
        dispatch(selectVis(visualizations[0].id))
      }

    }

  }, [vis, visualizations])


  const [drawerOpen, setDrawerOpen] = useState(false);

  const createNewVis = () => {
    const newVis: Partial<Visualization> = {
      chartType: 'Line',
      title: 'Untitled',
      xAxes: [],
      yAxes: [],
      series: []
    }
    dispatch(createVis(newVis))
  }

  const removeVisualization = (visualization) => {
    dispatch(removeVis(visualization))
  }

  const visTitle = (visualization: Visualization) => {
    return (
      <div className="tab-name-truncate">
        <span className="bp4-inline">
              <Popover2
                interactionKind="click"
                placement="bottom"           
                content={
                  <Menu>
                    <MenuItem text="Settings" icon="cog" onClick={() => toggleDrawer(visualization)} />
                    <MenuItem text="Delete" icon="trash" intent="danger" onClick={() => removeVisualization(visualization)} />

                  </Menu>
                }
                renderTarget={({ isOpen, ref, ...targetProps }) => (
                    <Button {...targetProps} elementRef={ref} small={true}  text="..."/>
                )}
                
              />
        &nbsp;{ visualization.title }

        </span>
      </div>
    );
  }

  const toggleDrawer = (vis: Visualization) => {
    dispatch(selectVis(vis.id))
    drawerOpen ? setDrawerOpen(false) : setDrawerOpen(true)
  }

  return (
    <div>
    { vis &&
    <Tabs 
      id="ReportVisusualizations" 
      vertical={true} 
      renderActiveTabPanelOnly={true} 
      defaultSelectedTabId={vis?.id}
      className="vis-tabs"
      onChange={
        (newVId, _) => {dispatch(selectVis(`${newVId}`))}}>
      { visualizations && visualizations.map((visualization) => 
        <Tab 
          panelClassName='container-fluid vis-panel col-sm-11' 
          key={visualization.id} 
          id={visualization.id} 
          title={visTitle(visualization)} 
          panel={
            <Row>
              <Col sm={12}>
                <Visualizer />
                { artifactSelections && artifactSelections.map((selection) => 
                  <SelectionViewer selection={selection} />
                )}
              </Col>
            </Row>
          } /> 
      )}

    </Tabs>
    }

      <Button text="+" onClick={createNewVis} minimal={true} /> 
      <Drawer 
        isOpen={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        position='left'
        size='400px'
        title="Edit Chart"
        icon="cog"
        portalClassName={`${darkModeState ? 'bp4-dark' : 'none'} vis-conf`}
      >
        <Container fluid>
          <Row>
            <Col>
              <VisualizationConfigurator/> 
            </Col>
          </Row>
        </Container>
      </Drawer>
    </div>
  );
}
