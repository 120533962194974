import  React, { useEffect } from 'react';
import { login, logout } from '../features/userSlice';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { User } from '../types/User';
import { Navigate } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

import { AuthCode, getTokens, TokenPayload, parseParams, validatePayload, getEmailFromIdToken } from './validator'


export const Callback = () => {
    const user = useAppSelector((state) => state.userData.user)
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const location = useLocation();


    useEffect(() => {
      const authCode: AuthCode = parseParams(window.location.href)
      if (validatePayload(authCode)) {
        getTokens(authCode)
          .then((res:any) => res.json())
          .then(
            (result: TokenPayload) => {
              dispatch(login({ 
                email: getEmailFromIdToken(result.id_token), 
                jwt: result.access_token,
                jwtId: result.id_token,
                refreshToken: result.refresh_token,
                expiresAt: Date.now() + (result.expires_in * 1000)
              }))    
            },
            (error: any) => {})
      }
    }, []);

    return (
      <div>
        { user && 
          <Navigate to='/' state={{ replace: true }} />
        }
        Logging In 
      </div>
      );
    

}
