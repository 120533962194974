import { reportApi } from './reportApi'
import { DashboardDataIndex, DataSegmentIndex, DashboardData } from '../types/DashboardData'
import { Artifact } from  '../types/Artifact'

const dataApi = reportApi.injectEndpoints({
  endpoints: (builder) => ({
    getDataIndex: builder.query<DashboardDataIndex[], null>({
      query: () => `/data_sets`,
      providesTags: (result, error, arg) => {
        if(!error) {
          return [{type: 'DataIndex'}]
        } else {
          return []
        }
      }
    }),

    getDataSegmentIndex: builder.query<DataSegmentIndex[], [string, number|string]>({
      query: (args) => `/data_sets/${args[0]}/${args[1]}`,
      providesTags: (result, error, args) => {
        if(!error) {
          return [{type: 'DataSegmentIndex', id: `${args[0]}-${args[1]}`}]
        } else {
          return []
        }
      }

    }),
    
    getDataSegment: builder.query<DashboardData[], [string, number]>({
      query: (args) => `/data_sets/${args[0]}/${args[1]}/all`,
      providesTags: (result, error, args) => {
        if(!error) {
          return [{type: 'Segment', id: `${args[0]}-${args[1]}`}]
        } else {
          return []
        }
      }

    }),
    getDataSet: builder.query<DashboardData, [string, number|string, string]>({
      query: (args) => `/data_sets/${args[0]}/${args[1]}/${args[2]}`,
      providesTags: (result, error, args) => {
        if(!error) {
          return [{type: 'DataSet', id: `${args[0]}-${args[1]}-${args[2]}`}]
        } else {
          return []
        }
      }
    }),

    //args reportId,dataSegmentIndex,startdate,endDate,searchQueryUUID
    getArtifactsData: builder.query<Artifact[], [string,string|number,number,number,string]>({
      query: (args) => ({
        url: `/data_sets/${args[0]}/${args[1]}/artifacts?start_date=${args[2]}&end_date=${args[3]}`,
        method: 'GET'
      }), 
    }),

  }),
  overrideExisting: true

});

export const {useLazyGetArtifactsDataQuery, useGetDataIndexQuery, useGetDataSegmentIndexQuery, useGetDataSegmentQuery, useGetDataSetQuery, useLazyGetDataSetQuery, useLazyGetDataSegmentIndexQuery } = dataApi
