import React, { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { allLocations, makeSelectLocationsByPath, loadLocations } from '../features/locationsSlice'
import { useLsQuery } from '../services/locationApi'
import { LocationPath } from './LocationPath'
import { Tag, Card } from '@blueprintjs/core'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { LocationNode } from '../types/LocationNode'

interface BrowsingPath {
  path: string,
  index: number,
  locationNode?: LocationNode,
  symPath: string
}
export interface SelectionEvent {
  path: string,
  select: boolean,
  symPath: string
}



export const LocationBrowser = ({root, selectedPaths, onSelectionChange}:{root: string, selectedPaths: string[], onSelectionChange: any}) => {
  const selector = makeSelectLocationsByPath()
  const locations = useAppSelector(allLocations)


  let [browsingPaths, setBrowsingPaths] = useState([{
    path: root,
    index: 0,
    symPath: root
  } as BrowsingPath ])


  const getOriginal = (location: LocationNode):LocationNode => {
    console.log(location)
    if (location.OriginalUUID !== undefined ){
      const original = locations.find(loc => loc.UUID == location.OriginalUUID)
      if (original) {
        return original
      } else {
        console.log('not found')
        return location
      }
    } else {
      return location
    }
  }



//  let [selectedPaths, setSelectedPaths] = useState(['/en/Russia/*'])
  const handleBrowse = (location: LocationNode, index: number):void => {
    const oLocation = getOriginal(location)
    const oPath = `${oLocation.Path}${oLocation.Name}/`
    const symPath = `${location.Path}${location.Name}/`
    setBrowsingPaths([...browsingPaths.filter((bPath) => bPath.index <= index),
      {
        path: oPath,
        index: index+1,
        locationNode: location,
        symPath: symPath
      }])
  }


  const isRedundant = (localSelectedPaths: string[], path: string) => {
    let redundant = false
    localSelectedPaths.every(sPath => {
      const [primary, wildcard] = sPath.split('*')
      if (sPath == path){
        redundant = true
      } else if(wildcard == '') {
         redundant = (path.indexOf(primary) == 0)
      }


      if (redundant) {
        return false
      }
    })
    return redundant
  }

  const handleSelection = (selectionEvent: SelectionEvent, index: number) => {
    let localSelectedPaths = [...selectedPaths]
    if (selectionEvent.select) {
      // add paths
      
      // ignore adds if already covered
      if (!isRedundant(localSelectedPaths, selectionEvent.path)) {
        // remove old paths that are redundant because of the new one
        const [newPrimary, wildcard] = selectionEvent.path.split('*')
        if (wildcard == '') {
           
          localSelectedPaths = localSelectedPaths.filter(oldPath => {
            const oldPrimary = oldPath.split('*')[0]
            return (oldPrimary.indexOf(newPrimary) != 0)
          })
        }

        localSelectedPaths=[...localSelectedPaths, selectionEvent.path]
       
      }
      // remove old paths if new path covers the old one

    } else {
      if (localSelectedPaths.indexOf(selectionEvent.path) >= 0) {
        localSelectedPaths = localSelectedPaths.filter(sPath => sPath != selectionEvent.path)
      } else {
        // find the path that currently covers this element
        console.log(localSelectedPaths)
        console.log(selectionEvent)
        console.log(index)
        let coveringPath = localSelectedPaths.filter(sPath => (selectionEvent.path.indexOf(sPath.split('*')[0]) == 0) && sPath.slice(-1) == '*')[0]
        if (coveringPath == undefined) {
           coveringPath = localSelectedPaths.filter(sPath => (selectionEvent.symPath.indexOf(sPath.split('*')[0]) == 0) && sPath.slice(-1) == '*')[0]

        }
        // find the index of the browsingPath where that covering element occurs
        const parentBPath = coveringPath.split('/*')[0].split('/').slice(0, -1).join('/') + '/'
        const coveringBrowsingPathIndex = browsingPaths.findIndex(bPath => bPath.path == parentBPath)
        const pathsToReify = browsingPaths.filter(bPath => bPath.index > coveringBrowsingPathIndex && bPath.index <= index).map(bPath => bPath.path)
        let newLocationPaths: string[] = []
        pathsToReify.forEach(ptr => {
          newLocationPaths = [
            ...newLocationPaths,
            ...locations.filter(loc => loc.Path == ptr).map(loc => {
              loc = getOriginal(loc)
              return loc.Path + loc.Name + '/*'
            })
          ]
        })
        newLocationPaths = newLocationPaths.map(loc => {
          let outputLoc = loc
          pathsToReify.forEach(ptr => {
            outputLoc = outputLoc.replace(ptr + '*', ptr)
          })
          return outputLoc
        })
          // for all browsingPaths > than that index up to and including the current index
          // reify all selection paths and create an array of them
          // remove each  selection that covers the element by dropping the '*'
          // remove the de-selected path
          // add the remainder to the selected paths list
        localSelectedPaths = [...localSelectedPaths.map(sPath => sPath.replace(coveringPath, coveringPath.replace('*', ''))), ...newLocationPaths]
        localSelectedPaths = localSelectedPaths.filter(loc => loc != selectionEvent.path)
      }

      // if covered by a parent *
        // remove parent *

    }
    onSelectionChange(localSelectedPaths)
    //setSelectedPaths(localSelectedPaths)
  }

  const renderBrowsingPaths = (bPath: BrowsingPath) => {
    const renderablePaths = browsingPaths.slice(-4)
    if(renderablePaths.findIndex(elet => elet.index == bPath.index) >= 0){
      return true
    } else {
      return false
    }

  }

  return (
    <div className="location-container">
    <Card className="location-browser">
      <Row>
      { browsingPaths.map((bPath, index) =>
          <Col sm={3} className={renderBrowsingPaths(bPath) ? 'visible' : 'hidden'} key={bPath.index}>
            <LocationPath path={bPath.path} handleBrowse={(location:LocationNode) => handleBrowse(location, bPath.index)} key={bPath.path} parentNode={bPath.locationNode} handleSelection={(selectionEvent: SelectionEvent) => handleSelection(selectionEvent, index)} selectedPaths={selectedPaths} altPath={bPath.symPath}/>
          </Col>
      )}
      </Row>
    </Card>
      { selectedPaths.map((path) => 
        <Tag className="selected-path-list-item" key={path}>{path}</Tag>
      )}
    </div>
  )
}
