import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import { Dashboard } from '../types/Dashboard'
import { Visualization } from '../types/Visualization'
import { ArtifactSelection2 } from '../types/ArtifactSelection2'


interface DashboardState {
  dashboards: Dashboard[],
  selectedDashboard: string|null
}

const initialState: DashboardState = {
  dashboards: [],
  selectedDashboard: null
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    loadDashboards: (state, action: PayloadAction<Dashboard[]>) => {
      return {...state, dashboards: action.payload.map(dash => ({...dash, saved: true} as Dashboard))}
    },
    selectDashboard: (state, action: PayloadAction<string>) => {
      return  {...state, selectedDashboard: action.payload}
    },
    //load from api
    loadDashboard: (state, action: PayloadAction<Dashboard>) => {
      if(action.payload.id) {
        const dashboardIndex = state.dashboards.findIndex(dash => dash.id == action.payload.id)
        if(dashboardIndex >= 0){
          state.dashboards[dashboardIndex] = {...action.payload, saved: true} as Dashboard
        }
      }      
    },
    //modify a dashboard in state
    updateDashboard: (state, action: PayloadAction<Dashboard>) => {
      if(action.payload.id) {
        const dashboardIndex = state.dashboards.findIndex(dash => dash.id == action.payload.id)
        if(dashboardIndex >= 0){
          state.dashboards[dashboardIndex] = {...action.payload, saved: false} as Dashboard
          
        }

      }      
    },
    updateDashboardVis: (state, action: PayloadAction<[string, Visualization]>) => {
       const index = state.dashboards.findIndex( ( dash: Dashboard ) => {
        return dash.id === action.payload[0]
      });
      if (index !== -1 && 'visualizations' in state.dashboards[index]) {
        const visIndex = state.dashboards[index].visualizations!.findIndex( v => {
          return v.id === action.payload[1].id
        });
        if (visIndex !== -1 ){
          state.dashboards[index].visualizations![visIndex] = action.payload[1]
          state.dashboards[index].saved = false
        }
      }    
    },
    removeVisualizationFromSelectedDashboard: (state, action: PayloadAction<string>) => {
      const index = state.dashboards.findIndex((dash: Dashboard) => dash.id === state.selectedDashboard)
      if(index !== -1){
        const visIndex = state.dashboards[index].visualizations?.findIndex((vis: Visualization) => vis.id === action.payload)
        if(visIndex !== -1 && visIndex !== undefined) {
          state.dashboards[index].visualizations?.splice(visIndex, 1)
        }
 
      }

    },
    updateDashboardUsers: (state, action: PayloadAction<[string, string[]]>) => {
     const index = state.dashboards.findIndex( ( dashboard: Dashboard ) => {
        return dashboard.id === action.payload[0]
      });
      if (index !== -1) {
        state.dashboards[index].users = action.payload[1]
      }  
    },
    updateDashboardArtifactSelections: (state, action: PayloadAction<[string, ArtifactSelection2[]]>) => {
      const index = state.dashboards.findIndex( ( dash: Dashboard) => {
        return dash.id === action.payload[0]
      });
      if (index !== -1){
        state.dashboards[index].artifactSelections = action.payload[1]
      }
    },

    // reportId, dataSegmentIndex, searchQueryUUID, checksums
    updateDashboardArtifactSelectionChecksums: (state, action: PayloadAction<[string, string, string, string[]]>) => {
      const index = state.dashboards.findIndex( (dash) => dash.id == state.selectedDashboard)
      if (index !== -1 && Array.isArray(state.dashboards[index].artifactSelections)){
        let artifactSelections = [...(state.dashboards[index].artifactSelections || [])]
        const aSIndex = artifactSelections.findIndex((selection) => {
          return selection.uuid == action.payload[2] && selection.dataSegmentIndex == action.payload[1] &&
            selection.reportId == action.payload[0]
        })
        if(aSIndex !== -1) {
           artifactSelections[aSIndex] = { ...artifactSelections[aSIndex], checksums: action.payload[3]}
        }
        state.dashboards[index].artifactSelections = artifactSelections
      }
    },
  }
})

export const selectDashboards = (state: RootState) => state.dashboards.dashboards

export const selectedDashboardId = (state: RootState) => state.dashboards.selectedDashboard

export const selectedDashboard = (state: RootState) => {
  const dashboard = state.dashboards.dashboards.find((dash) => dash.id == state.dashboards.selectedDashboard)
  return dashboard ? dashboard : null
}

export const makeVisualizationSelector = () => {
  const selectedVisualization = createSelector(
    [
      selectedDashboard,
      (state, id) => id
    ],
    (dashboard, id) => {
      return dashboard !== null && dashboard.visualizations !== undefined ? 
        (dashboard.visualizations.find(vis => vis.id == id) || null)
      :
        null
    }
  )
  return selectedVisualization

}

export const selectedDashboardUsers = createSelector([selectedDashboard], (dashboard) => {
  if(dashboard && dashboard.users) {
    return dashboard.users
  } else {
    return []
  }
})




export const { updateDashboardArtifactSelections, updateDashboardArtifactSelectionChecksums, removeVisualizationFromSelectedDashboard, updateDashboardUsers, loadDashboard, loadDashboards, selectDashboard, updateDashboard, updateDashboardVis } = dashboardSlice.actions

export default dashboardSlice.reducer
