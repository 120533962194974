import  React, { useState }  from 'react';
import { login, logout } from '../features/userSlice';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { User } from '../types/User';
import { Navigate, useLocation } from "react-router-dom";
import { attemptSilentLogin } from './validator';
import { authorizeUrl } from './authConfig'

export const RequireAuth = ({ children }: { children: JSX.Element }) => {

    const user = useAppSelector((state) => state.userData.user)
    const dispatch = useAppDispatch()
    let location = useLocation();
    if (!user) {
    return (
      <Navigate to="/login" state={{ from: location, replace: true }} />
    )
  }

  return children;
}
