import * as jose from 'jose'
import { authConfig, authorizeUrl } from './authConfig'
import { Base64 } from 'js-base64';

export interface TokenPayload {
  id_token: string,
  access_token: string,
  refresh_token: string,
  expires_in: number,
  token_type: string
}

export interface AuthCode {
  code: string|null,
  state: string|null
}


export const getEmailFromIdToken = (id_token: string) => {
  const data = id_token.split('.')[1]
  const decoded = Base64.decode(data)
  const obj = JSON.parse(decoded)
  return obj.email

}

export const validatePayload = (authCode: AuthCode) => {
      if (authCode.state == localStorage.getItem('state_key')){
        localStorage.removeItem('state_key')
        return true
      } else {
        return false
      }
}

export const refreshTokens = (refreshToken:string):Promise<any> => {
  let headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded')
  return fetch(`${authConfig.endpoint}/token`, {
    method: 'post',
    headers: headers,
    body: `grant_type=refresh_token&client_id=${authConfig.client_id}&refresh_token=${refreshToken}&redirect_uri=${window.location.origin}/callback`
  })
}

export const  logoutOAuth = () => {
  return `${authConfig.endpoint}/logout?client_id=${authConfig.client_id}&logout_uri=${window.location.origin}`
}



export const getTokens = (authCode: AuthCode):Promise<any> => {
  let headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded')
  return fetch(`${authConfig.endpoint}/token`, {
    method: 'post',
    headers: headers,
    body: `grant_type=authorization_code&client_id=${authConfig.client_id}&code=${authCode.code}&redirect_uri=${window.location.origin}/callback`
  })
}

export const attemptSilentLogin = ():Promise<any>|boolean => {
  fetch(authorizeUrl)
  return false
}

export const parseParams = (url_string: string):AuthCode => { 
      const myUrl = new  URL(url_string)
      const code = myUrl.searchParams.get('code')
      const state = myUrl.searchParams.get('state')
      return {
        code: code,
        state: state
      }

}

/*
export const parseHashOld = (hash: string):Promise<any> => {
      const myUrl = new  URL("https://localhost/?" + hash.slice(1))
      const id_token = myUrl.searchParams.get('id_token')
      const access_token = myUrl.searchParams.get('access_token')
      const state =  myUrl.searchParams.get('state')
      return {
        id_token: id_token,
        access_token: access_token,
        state: state
      }


}
*/
export const validateToken = (token: string) => {

}
