import  React  from 'react';
import { Callout } from "@blueprintjs/core";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


export const Login = () => {
  
return (
  <Callout>
    <h5 className="bp4-heading">Please Login To Continue</h5>
    <p>Filter Explorer requires you to register and login. For sales please contact us.</p>
  </Callout>
);
}
