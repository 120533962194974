import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import type { LocationNode } from '../types/LocationNode'
import { LocationSourceCount } from '../types/LocationSourceCount'
interface LocationsState {
  locations: LocationNode[],
}

const initialState: LocationsState = {
  locations: [],
}

export const locationsSlice = createSlice({
  name: 'locationsData',
  initialState,
  reducers: {
    loadLocations: (state, action: PayloadAction<LocationNode[]>) => {
      let newLocations = action.payload.filter((location) => {
        return state.locations.findIndex((oldLocation) => {
          return oldLocation.UUID == location.UUID
        }) < 0
      })
      let locations = [...state.locations, ...newLocations]
      return {...state, locations: [...locations]}
    },
    updateSourceCount: (state, action: PayloadAction<[LocationSourceCount, string]>) => {

      let locationIndex = state.locations.findIndex(loc => loc.UUID == action.payload[1])
      if(locationIndex > -1) {
        state.locations[locationIndex].Total = action.payload[0].source_count
      }
    },
    updateSourceCountNoChildren: (state, action: PayloadAction<[LocationSourceCount, string]>) => {

      let locationIndex = state.locations.findIndex(loc => loc.UUID == action.payload[1])
      if(locationIndex > -1) {
        state.locations[locationIndex].TotalNoChildren = action.payload[0].source_count
      }
    },

  }

})


export const { loadLocations, updateSourceCount, updateSourceCountNoChildren } = locationsSlice.actions

export const makeSelectLocationsByPath = () => {
  const selectLocationsByPath = createSelector(
    [
      state => state.locationsData.locations,
      (state, path) => {
        return path
      }
    ],
    (locations, path) => {
      return locations.filter(location => location.Path == path)
    }
  )
  return selectLocationsByPath
}

export const allLocations = (state: RootState) => {
  return state.locationsData.locations
}

export default locationsSlice.reducer
