import './App.scss';
import { Layout } from "./core/components/Layout";
import { DataFilters } from "./core/components/DataFilters"
import { DashboardSpace } from "./core/components/DashboardSpace"
import { Dashboard2Space } from "./core/components/Dashboard2Space"

import { Home } from "./core/components/Home"
import 'normalize.css/normalize.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import "@blueprintjs/select/lib/css/blueprint-select.css";
import { RequireAuth } from './core/auth/RequireAuth';
import { Login } from './core/components/Login';
import { Organizations } from './core/components/Organizations';
import { Routes, Route } from "react-router-dom";
import { Callback } from './core/auth/Callback'

import { getDarkMode, setDarkMode } from './core/Prefs' 
import { useEffect, useState, createContext } from 'react'



export const DarkModeContext = createContext()
function App() {

  const [darkModeState, setDarkModeState] = useState(getDarkMode())
  useEffect(() => {
    setDarkMode(darkModeState)
  }, [darkModeState, setDarkMode]) 



  return (

    <DarkModeContext.Provider value={{darkModeState, setDarkModeState}}>
      <div className={`${darkModeState? 'bp4-dark': ''} body-wrapper`}>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/orgs" element={
              <RequireAuth>
                <Organizations/>
              </RequireAuth>
              } />
            <Route
              path="/"
              element={
                <RequireAuth>
                  <Home />
                </RequireAuth>
              } />
            <Route
              path="/data-filters"
              element={
                <RequireAuth>
                  <DataFilters />
                </RequireAuth>
              } />
            <Route path="/dashboards" element={
              <RequireAuth>
                <DashboardSpace />
              </RequireAuth>
              } />
            <Route path="/dashboards2" element={
              <RequireAuth>
                <Dashboard2Space />
              </RequireAuth>
              } />
          </Route>
          <Route path="/callback" element={<Callback />} />
        </Routes>
      </div>

    </DarkModeContext.Provider>
  );
}




export default App;
