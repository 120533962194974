import  React, { useState, useEffect }  from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { removeReportSearchQueryUsages, selectSearchQueriesSaved,  addReportSearchQueryUsages, updateReportSearchQueryUsages, addSearchQuery, updateSearchQuery, selectedReportId, loadReportSearchQueryUsages, selectedReportSearchQueryUsages, selectSearchQueries } from '../features/reportsSlice';
import { SearchQuery } from '../types/SearchQuery';
import { SearchQueryUsage } from '../types/SearchQueryUsage';
import { Callout, Card, Button, Divider, Dialog, Classes } from "@blueprintjs/core";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import {v4} from 'uuid';
import { useUpdateSearchQueryUsagesMutation, useUpdateSearchQueryMutation, useCreateSearchQueryMutation } from '../services/reportApi' 
import { SearchQueryUsageDisplay } from './SearchQueryUsageDisplay'
import { featureConfig } from '../featureConfig'



export const QueryBook = () => {
  const user = useAppSelector((state) => state.userData.user)
  const textDirection = featureConfig?.filterTextDirections[user?.filterModule || 0] || 'ltr'

  const searchQueries = useAppSelector(selectSearchQueries)
  const searchQueryUsages = useAppSelector(selectedReportSearchQueryUsages)
  const reportId = useAppSelector(selectedReportId)
  const sqSaved = useAppSelector(selectSearchQueriesSaved)

  const dispatch = useAppDispatch()

  const saveSearchQueries = () => {
    searchQueries?.forEach((sq: SearchQuery) => {

      if(sq.local && reportId) {
        createSearchQueryApi([reportId, sq])
      } else if(sq.updated && reportId) {
        updateSearchQueryApi([reportId, sq])        
      }
    })
    
    //const currentUUIDs = searchQueryUsages?.filter(x => x.active === true)?.map(x => x.search_query_id) || []
    //updateSearchQueryUsagesApi([reportId, currentUUIDs])
  }




  const [
    createSearchQueryApi, // This is the mutation trigger
    { isLoading: isCreating, isSuccess: isCreated }, // This is the destructured mutation result
  ] = useCreateSearchQueryMutation()

  const [
    updateSearchQueryApi, // This is the mutation trigger
    { isLoading: isUpdating }, // This is the destructured mutation result
  ] = useUpdateSearchQueryMutation()

  const [
    updateSearchQueryUsagesApi, // This is the mutation trigger
    { isLoading: isUpdatingUsages }, // This is the destructured mutation result
  ] = useUpdateSearchQueryUsagesMutation()



  const addSearchQueryUsage = (uuid: string) => {
    const currentUUIDs = searchQueryUsages?.map((x: SearchQueryUsage) => x.search_query_id) || []
    const activeCurrentUUIDs = searchQueryUsages?.filter(x => x.active === true)?.map(x => x.search_query_id) || []
    const newSQU = {search_query_id: uuid, active: true, order: activeCurrentUUIDs.length} as SearchQueryUsage
    if (reportId && !currentUUIDs.includes(uuid)){
      dispatch(addReportSearchQueryUsages([reportId, newSQU])) 
    } else if (reportId && !activeCurrentUUIDs.includes(uuid)) {
      // we are updating an inactive one
      dispatch(updateReportSearchQueryUsages([reportId, newSQU])) 
    }
  }

  const removeSearchQueryUsage = (uuid: string) => {
    if (reportId) {
      dispatch(removeReportSearchQueryUsages([reportId, uuid]))
    }
  }

  const createSearchQueryLocal = () => {
    const newSearchQuery = {name: '', query:'', id: v4(), local: true} as SearchQuery
    console.log(newSearchQuery)
    dispatch(addSearchQuery(newSearchQuery))
  }


  const updateSearchQueryName = (sq: SearchQuery, name: string) => {
    const newSq = {...sq, name: name, updated: true} as SearchQuery;
    dispatch(updateSearchQuery(newSq))
  }

  const updateSearchQueryQuery = (sq: SearchQuery, query: string) => {
    const newSq = {...sq, query: query, updated: true} as SearchQuery;
    dispatch(updateSearchQuery(newSq))
  }


  return (
    <Container fluid className="overflow-hidden">
      <Row className={Classes.DIALOG_BODY}>
        <Col>
          <h5>My Queries</h5>
            <Callout intent="primary" title="Query Syntax">
              <Row>
                <Col md={6}>
                  <ul>
                    <li>+ signifies AND operation</li>
                    <li>| signifies OR operation</li>
                    <li>- negates a single token</li>
                    <li>" wraps a number of tokens to signify a phrase for searching</li>
                  </ul>
                </Col>
                <Col md={6}>
                  <ul>
                    <li>* at the end of a term signifies a prefix query</li>
                    <li>( and ) signify precedence</li>
                    <li>~N after a word signifies edit distance (fuzziness)</li>
                    <li>~N after a phrase signifies slop amount</li>
                  </ul>
                </Col>
              </Row>
            </Callout>
            <Card className="query-book-queries-list">
            <Row>
              <Col>
                <Row className="g-1">
                  <Col md={3}>
                    Name
                  </Col>
                  <Col md={8}>
                    Query
                  </Col>
                  <Col md={1}>
                  </Col>

                </Row>

                { searchQueries?.map((sq) => 
                  <Row className="g-1">
                    <Col md={3}>
                      <input className={Classes.INPUT + " bp4-fill"} value={sq.name} onChange={(e) => updateSearchQueryName(sq, e.target.value)}/>
                    </Col>
                    <Col md={8}>
                      <input dir={textDirection} className={Classes.INPUT + " bp4-fill"} value={sq.query} onChange={(e) => updateSearchQueryQuery(sq, e.target.value)}/>
                    </Col>
                    <Col md={1}>
                       <Button small icon="add-row-bottom" onClick={() => addSearchQueryUsage(sq.id)}/>
                    </Col>
                  </Row>

                )}
                <Row className="g-1">
                  <Col md={12}>
                    <Button small icon="add" onClick={() => createSearchQueryLocal()}/>
                  </Col>
                </Row>

              </Col>
            </Row>
            </Card>
            <Row className="query-book-save-row">
              <Col>
                <Button small icon="floppy-disk" disabled={sqSaved} onClick={() => saveSearchQueries()}>Save Queries</Button>

              </Col>
            </Row>
          <Divider />
          <h5>In Use</h5>
          { searchQueries && searchQueryUsages?.map((squ) =>
            <div>
            { squ.active &&
            <Row className="g-1">
              <Col md="11">
                <SearchQueryUsageDisplay order={squ.order} active={squ.active} sQID={squ.search_query_id}/>
              </Col>
              <Col md="1">
                <Button small icon="remove" className="query-book-remove" onClick={() => removeSearchQueryUsage(squ.search_query_id)}/>
              </Col>
            </Row>
            }
            </div>
          )}
        </Col>
      </Row>
      <Row className={Classes.DIALOG_FOOTER}>
        <Col>
        </Col>
      </Row>
    </Container>
  );
}

