import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { RootState } from '../../app/store'
import { serviceConfig } from './serviceConfig'

export const locationApi = createApi({
  reducerPath: 'locationApi',
  tagTypes: ['Location'],
  baseQuery: fetchBaseQuery({
    baseUrl: serviceConfig.locationApi,
    prepareHeaders: (headers, {getState}) => {
      const token = (getState() as RootState).userData.user?.jwt || ''
      if(token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    ls: builder.query({
      query: (path) => `/dir?path=${encodeURIComponent(path)}`,
      providesTags: (result, error, arg) => {
        if (!error) {
          return result && result.length > 0 ? result.map((r) => ({ type: 'Location', id: r.UUID})) : []
        } else {
          return []
        }

      }
    }),
    getByUUID: builder.query({
      query: (uuid) => `/dir?uuid=${encodeURIComponent(uuid)}`,
      providesTags: (result, error, arg) => {
        if (!error) {
          return result && result.length > 0 ? result.map((r) => ({ type: 'Location', id: r.UUID})) : []
        } else {
          return []
        }

      }
    }),

  }),

});

export const { useLsQuery, useLazyGetByUUIDQuery } = locationApi
