import  React, {useEffect, useState}  from 'react';
import { Alert, Dialog, Spinner, ButtonGroup, Divider, Button, Card, Callout } from "@blueprintjs/core";
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { Report } from './Report';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { mySelectedOrg} from '../features/orgsSlice';
import { useNavigate, Link } from "react-router-dom";
import { useDeleteDashboardMutation, useLazyGetDashboardQuery, useGetDashboardsQuery } from '../services/dashboardApi'
import { useCreateDashboardMutation } from '../services/dashboardApi'
import { Breadcrumbs } from "./Breadcrumbs";
import { ShareManager } from './ShareManager'
import { UserPlusIcon } from '@heroicons/react/24/solid'
import { loadDashboard, selectDashboards, selectedDashboard, loadDashboards } from '../features/dashboardSlice'
import { loadDataIndices } from '../features/dashboardDataSlice'
import { useGetDataIndexQuery } from '../services/dataApi'



import { DashboardSelector } from './DashboardSelector'
import { Dashboard} from './Dashboard'
import { Dashboard as DashboardType } from '../types/Dashboard'

export const DashboardSpace= () => {
  const user = useAppSelector((state) => state.userData.user)
  const myOrg = useAppSelector(mySelectedOrg)

  const dashboards = useAppSelector(selectDashboards)

  const dashboard = useAppSelector(selectedDashboard)

  const dispatch = useAppDispatch()
  const [shareOpen, setShareOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)


  const { data: dashboardsData, error: dashboardsError, isLoading: dashboardsIsLoading } = useGetDashboardsQuery(null)


  const [triggerGetDashboard, resultGetDashboard, lastPIGetDashboard] = useLazyGetDashboardQuery()

  const [
    createDashboardApi, // This is the mutation trigger
    { isLoading: isCreating, isSuccess: isCreated }, // This is the destructured mutation result
  ] = useCreateDashboardMutation()


  // Dashboards require knowing the names of "Filters" (aka reports)
  const { data: dataIndexData, error: dataIndexError, isLoading: dataIndexIsLoading } = useGetDataIndexQuery(null)
  useEffect(() => {
    if(dataIndexData) {
      dispatch(loadDataIndices(dataIndexData)) 
     }
  }, [dataIndexData, dispatch])


  const [deleteDashboardApi, {isLoading: deleteDashboardLoading}] = useDeleteDashboardMutation()

  const deleteDashboard = (dashboard: DashboardType) => {
    deleteDashboardApi(dashboard.id)
    setDeleteOpen(false)
  }

  useEffect(() => {
    if(dashboardsData) {
      dispatch(loadDashboards(dashboardsData)) 
     }
  }, [dashboardsData, dispatch])

  useEffect(() => {
    if(dashboard && dashboard.visualizations == undefined) {
      triggerGetDashboard(dashboard.id)
    }

  }, [dashboard])

  useEffect(() => {
    if(resultGetDashboard?.status == "fulfilled" && resultGetDashboard?.data){
      dispatch(loadDashboard(resultGetDashboard.data))
    }
  }, [resultGetDashboard])

  const createNewDashboard = () => {
    const dashboard = {
      name: 'Untitled',
      visualizations: []
    }

    createDashboardApi(dashboard as Partial<DashboardType>) 
  }

  const dashboardSaved = (): boolean => {
    if(dashboard){
      return dashboard?.saved ? true : false
    }
    return false
  }
  const breadcrumbs =  [
    {href: "/", text: 'Home'},
    {href: "/dashboards", text: 'Dashboards'}
  ]
  return (
    <Row>
      <Col>
        <Card>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Divider />
      { user && myOrg &&
      <div>
            { dashboardsIsLoading && 
               <Spinner size={30}/>
            }
            <ButtonGroup minimal={true}>


              { dashboards && dashboards.length > 0 &&
                <DashboardSelector />
              }
              <Divider />
              <Button icon="add" onClick={() => createNewDashboard()} text="Create New Dashboard" disabled={(dashboard != undefined && dashboard != null && !dashboardSaved())}/>
              { dashboard &&
                <Button icon={<UserPlusIcon className="h-16 w-16"/>} onClick={() => setShareOpen(!shareOpen)} text="Manage Access"/>
              }
              { dashboard &&
                <Dialog isOpen={shareOpen} title="Manage Access" onClose={(event: any) => setShareOpen(false)} className="manage-share-dialog">
                  <Card>
                    <Row>
                      <Col>
                        <ShareManager groupType="dashboards" />
                      </Col>
                    </Row>
                  </Card>
                </Dialog>

              }
              <Divider />                                              
              { dashboard &&
                 <div>
                  <Button icon="trash" intent="danger" onClick={() => setDeleteOpen(true)}/>
                  <Alert
                    isOpen={deleteOpen}
                    intent="danger"
                    icon="trash"
                    canOutsideClickCancel={true}
                    canEscapeKeyCancel={true}
                    confirmButtonText="Delete it!"
                    cancelButtonText="Cancel"
                    onCancel={() => setDeleteOpen(false)}
                    onConfirm={() => deleteDashboard(dashboard)} 
                  >Permanently Delete this Dashboard?</Alert>
                </div>
            }
            </ButtonGroup>
            <Divider />
            { dashboard && 
              <Dashboard />
            }
          </div>
      }
        </Card>
      </Col>
    </Row>
  );
}
