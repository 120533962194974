import { reportApi } from './reportApi'
import { Dashboard } from '../types/Dashboard'

const dashboardApi = reportApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashboards:  builder.query<Array<Dashboard>, null>({
      query: () => '/dashboards',
      providesTags: (result, error) => {
        if(!error) {
          return [{type: 'Dashboard', id: 'INDEX'}]
        } else {
          return []
        }
      }
    }),
    getDashboard: builder.query<Dashboard, string>({
      query: (uuid) => `/dashboards/${uuid}`,
      providesTags: (result, error, args) => {
        if(!error) {
          return [{type: 'Dashboard', id: args}]
        } else {
          return []
        }
      }
    }),

    createDashboard: builder.mutation<Dashboard, Partial<Dashboard>>({
      query: (body) => ({
        url:  '/dashboards',
        method: 'POST',
        body: {dashboard: body}
      }),
      invalidatesTags: (result, error, arg) => {
        return result?
          [{type: 'Dashboard', id: 'INDEX'}]
        :
          []
      }
    }),
    updateDashboard: builder.mutation<Dashboard, Dashboard>({
      query: (body) => ({
        url: `/dashboards/${body.id}`,
        method: 'PUT',
        body: {dashboard: body}
      }),
      invalidatesTags: (result, error, args) => {
        return result? 
          [{type: 'Dashboard', id: 'INDEX'}, {type: 'Dashboard', id: args.id}]
        :
          []
      }
    }),
    deleteDashboard: builder.mutation<{success: boolean}, string>({
      query: (arg) => ({
        url: `/dashboards/${arg}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error) => {
        return result?
          [{type: 'Dashboard', id: 'INDEX'}]
        :
          []
      }
    })
  }),


  overrideExisting: true

});


export const { useDeleteDashboardMutation, useGetDashboardsQuery, useLazyGetDashboardQuery, useCreateDashboardMutation, useUpdateDashboardMutation } = dashboardApi 
