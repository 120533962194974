import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import type { Location } from '../types/Location'
import type { ArtifactType } from '../types/ArtifactType'
// Define a type for the slice state
interface ReportOptionsState {
  locations: Location[],
  artifactTypes: ArtifactType[]
}

// Define the initial state using that type
const initialState: ReportOptionsState = {
  locations: [
  ],
  artifactTypes: [
  ]
}

export const reportOptionsSlice = createSlice({
  name: 'reportOptions',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateLocations: (state, action: PayloadAction<Location[]>) => {
      state.locations = action.payload
    },
    updateArtifactTypes: (state, action: PayloadAction<ArtifactType[]>) => {
      state.artifactTypes = action.payload
    }
  },
})

export const { updateLocations, updateArtifactTypes } = reportOptionsSlice.actions

// Other code such as selectors can use the imported `RootState` type

export default reportOptionsSlice.reducer
