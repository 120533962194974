import  React, { useState, useEffect }  from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { selectSearchQueries } from '../features/reportsSlice';
import { SearchQuery } from '../types/SearchQuery'
import { Button, Card, Elevation } from "@blueprintjs/core";

export const SearchQueryUsageDisplay = (params: any) => {
  const searchQueries = useAppSelector(selectSearchQueries)
  const [selectedSearchQuery, setSelectedSearchQuery] = useState<SearchQuery|null>(null)
  useEffect(() =>{
    if (searchQueries) {
      const index = searchQueries.findIndex( (searchQuery: SearchQuery) => {
        return searchQuery.id == params.sQID
      })
      setSelectedSearchQuery(searchQueries[index])
    }
  },[searchQueries, params])

  return (
    <div>
      { selectedSearchQuery && params.active &&
        <Card  title={selectedSearchQuery.query} elevation={Elevation.ONE} className="squ-display">
          <div>{selectedSearchQuery.name}</div>
        </Card>
      }
    </div>
  );
}
