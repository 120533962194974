import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { RootState } from '../../app/store'
import { serviceConfig } from './serviceConfig'


export const usersOrgsApi = createApi({
  reducerPath: 'usersOrgsApi',
  tagTypes: ['InternalUsers', 'Orgs', 'Me', 'MyOrgs', 'OrgUsers'],
  baseQuery: fetchBaseQuery({
    baseUrl: serviceConfig.usersOrgsApi,
    prepareHeaders: (headers, {getState}) => {
      const token = (getState() as RootState).userData.user?.jwt || ''
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    getMe: builder.query({
      query: () => '/me',
      providesTags: (result) =>
        result? [{type: 'Me', id: 'me'}] : []
    }),
    getMeOrgs: builder.query({
      query: () => '/me/orgs',
      providesTags: (result) =>
        result? [{type: 'MyOrgs', id: 'me'}] : []
    }),
    getOrgUsers: builder.query({
      query: (uuid) => `/admin/orgs/${uuid}/users/`,    
      providesTags: (result) =>
        result? [{type: 'OrgUsers', id: 'ALL'}] : []
    }),
    createOrgUser: builder.mutation({
      query: (payload) => ({
        url: `/admin/orgs/${payload.uuid}/users/`,
        method: 'POST',
        body: {"email": payload.email, "role": payload.role}
      }),
      invalidatesTags: (result, error, arg) => {
        return result ?
          [{type: "OrgUsers", id: 'ALL'}, {type: "MyOrgs"}]
        :
          []
      } 
    }),
    delOrgUser: builder.mutation({
      query: (payload) => ({
        url: `/admin/orgs/${payload.org_uuid}/users/${payload.user_uuid}`,
        method: 'DELETE', 
      }),
      invalidatesTags: (result, error, arg) => {
        return result ?
          [{type: "OrgUsers"}, {type: "MyOrgs"} ]
        :
          []
      }
    })
  })
});

export const { useCreateOrgUserMutation, useLazyGetOrgUsersQuery, useLazyGetMeQuery, useLazyGetMeOrgsQuery, useDelOrgUserMutation } = usersOrgsApi

