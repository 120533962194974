import  React, { useEffect, useState }  from 'react';

import { Label,  ButtonGroup, Classes, Dialog, Button, Card, Divider } from "@blueprintjs/core";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { updateDashboard as updateDashboardState, selectedDashboard } from '../features/dashboardSlice'

import { useAppSelector, useAppDispatch} from '../../app/hooks'
import { Dashboard as DashboardType } from '../types/Dashboard'
import { Visualization } from '../types/Visualization'

import { useUpdateDashboardMutation } from '../services/dashboardApi'

import {v4} from 'uuid';

import {DashboardPanel} from './DashboardPanel'


export const Dashboard = () => {

  const dashboard = useAppSelector(selectedDashboard)

  const dispatch = useAppDispatch()  
  
  const [
    updateDashboardApi, // This is the mutation trigger
    { isLoading: isCreating, isSuccess: isCreated }, // This is the destructured mutation result
  ] = useUpdateDashboardMutation()

  const updateDashboard = () => {
    if(dashboard) {
      updateDashboardApi(dashboard)
    }
  }

  const [editNameOpen, setEditNameOpen] = useState(false)

  const addVis = () => {
    if(dashboard){
      const newVis: Visualization = {
        chartType: 'Line',
        title: 'Untitled',
        id: v4(),
        xAxes: [],
        yAxes: [],
        series: []
      }

      const newDash: DashboardType = {...dashboard, visualizations: [...(dashboard?.visualizations || []), newVis]}
      dispatch(updateDashboardState(newDash))
    }
  }

  const updateName = (event) => {
    if(dashboard){
      const newDash: DashboardType = {...dashboard, name: event.target.value}
      dispatch(updateDashboardState(newDash))
    }
  }

  if(dashboard){
    return (
      <Row>
        <Col xs={12}>
          { dashboard.visualizations?.map((vis) =>
              <DashboardPanel visualizationId={vis.id} />   
          )}
          <ButtonGroup>
            <Button icon="cog" onClick={() => setEditNameOpen(!editNameOpen)} />
            <Button icon="floppy-disk" onClick={() => updateDashboard()} />
            <Button icon="series-add" onClick={() => addVis()} />
          </ButtonGroup>
          <Dialog isOpen={editNameOpen} title="Edit Dashboard" onClose={(event: any) => setEditNameOpen(false)}>
            <Card>
            <Row>
              <Col>
                <Label>Name
                  <input className={Classes.INPUT + " bp4-fill"} type="text" value={dashboard?.name || ''} onChange={(event: any) => updateName(event)}/>                       </Label>
                <Button text="close" intent="primary" onClick={(event)=> setEditNameOpen(false)} />
              </Col>
            </Row>
            </Card>
          </Dialog>

        </Col>
      </Row>
    );
  } else {
    return (<p>loading...</p>)
  }

}
