import  React, { useState, useEffect, useCallback }  from 'react';
import { Switch,  Spinner, Label, HTMLSelect, Classes,  ButtonGroup, Button, Card, HTMLTable } from "@blueprintjs/core";

import { useAppSelector, useAppDispatch } from '../../app/hooks'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { selectMe, selectOrgUsers, loadOrgUsers, loadMe, loadMeOrgs, selectMyOrg, mySelectedOrg} from '../features/orgsSlice';
import { updateReportUsers, selectedReportId, selectedReportUsers } from '../features/reportsSlice';
import { useGetSharesQuery, useAddShareMutation, useRemoveShareMutation } from '../services/reportApi';
import { selectedDashboardId, updateDashboardUsers, selectedDashboardUsers} from '../features/dashboardSlice';

export const ShareManager = ({groupType}:{groupType: 'reports'|'dashboards'}) => {

  const reportId = useAppSelector(selectedReportId);
  const dashboardId = useAppSelector(selectedDashboardId);

  const reportUsers = useAppSelector(selectedReportUsers)
  const dashboardUsers = useAppSelector(selectedDashboardUsers)
  let users: string[]

  let groupId: string|null;

  let updateShareUsers: any;
  switch(groupType){
    case 'reports':
      groupId = reportId
      users = reportUsers
      updateShareUsers = updateReportUsers
      break
    case 'dashboards':
      groupId = dashboardId
      users = dashboardUsers
      updateShareUsers = updateDashboardUsers
      break
  }


  const myOrg = useAppSelector(mySelectedOrg)
  const me = useAppSelector(selectMe)
  const { data, error, isLoading } = useGetSharesQuery([groupType, groupId || '']) 
  const dispatch = useAppDispatch()

  const [removeShareApi, {isLoading: removeShareLoading }] = useRemoveShareMutation()
  const [addShareApi, {isLoading: addShareLoading }] = useAddShareMutation()

  useEffect(() => {
    if (data && groupId){
        dispatch(updateShareUsers([groupId, data.users]))
      }
  }, [data, dispatch, groupId])
 
  const changeState = (uuid) => {
    if (users.includes(uuid) && groupId){
      removeShareApi([groupType, groupId, uuid])
    } else if(groupId){
      addShareApi([groupType, groupId, uuid])
    }

  }
  return (
    <Row>      
      <Col>
      { myOrg && myOrg.org.users && users &&
        <HTMLTable className="bp4-html-table full-width" bordered={true} interactive={true} striped={true}>
          <thead>
            <tr>
              <th>Email</th><th>Access</th>
            </tr>
          </thead>
          <tbody>
        { myOrg.org.users.map((o_user) => ( 
          <tr key={o_user.uuid}>
            <td>{ o_user.email }</td>
            <td>

                <Switch disabled={o_user.uuid == me?.uuid} checked={users.includes(o_user.uuid)} label="Shared" onChange={() => changeState(o_user.uuid)} innerLabel="No" innerLabelChecked="Yes"/>
            </td>
          </tr>
        ))}
          </tbody>
        </HTMLTable>
      }
      </Col>
    </Row> 
  );
}
