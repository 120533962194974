import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import { DashboardData, DashboardDataIndex, DataSegmentIndex } from '../types/DashboardData'
import { SeriesConfig, SegmentCoords } from '../types/SeriesConfig'

interface DashboardDataState {
  dataIndices: DashboardDataIndex[],
  dataSegmentIndices: DataSegmentIndex[],
  data: DashboardData[]

}

const initialState: DashboardDataState = {
  dataIndices: [],
  dataSegmentIndices: [],
  data: []
}

export const dashboardDataSlice = createSlice({
  name: 'dashboardData',
  initialState,
  reducers: {
    loadDataIndices: (state, action: PayloadAction<DashboardDataIndex[]>) => {
      return {...state, dataIndices: action.payload }
    },
    loadDataSets: (state, action: PayloadAction<DashboardData[]>) => {
      // overwrite dupes, add the rest
      const data = [...state.data]
      action.payload.forEach(newDataSet => {
        const oldDSIndex = data.findIndex(oldDataSet => oldDataSet.id == newDataSet.id &&
                                                  oldDataSet.data_segment_index == newDataSet.data_segment_index &&
                                                  oldDataSet.report_id == newDataSet.report_id
                                    )
        if(oldDSIndex > -1){
          data[oldDSIndex]= newDataSet
        } else {
          data.push(newDataSet)
        }
      })
      state.data = data
    },
    loadSegmentIndices: (state, action: PayloadAction<DataSegmentIndex[]>) => {
      const segInds = [...state.dataSegmentIndices]
      action.payload.forEach(newSegInd => {
        const oldSegIndIndex = segInds.findIndex(oldSegInd => oldSegInd.id == newSegInd.id &&
                                                              oldSegInd.data_segment_index == newSegInd.data_segment_index &&
                                                              oldSegInd.report_id == newSegInd.report_id

                                            )
        if(oldSegIndIndex > -1) {
          segInds[oldSegIndIndex] = newSegInd
        } else {
          segInds.push(newSegInd)
        }
      })
      state.dataSegmentIndices = segInds
    }
  }
})

export const selectDataIndices = (state: RootState) => {
  return state.dashboardData.dataIndices
}

export const makeDataSetSelector = () => {
  const selectedDataSet = createSelector(
    [
      state => state.dashboardData.data,
      (state, series) => series
    ],
    (data, series) => {
      const dataSets:DashboardData[] = []
        series.forEach((ser) => {
          const dataSet = data.find(ds => ds.report_id == ser.reportId &&
                                         ds.data_segment_index == ser.dataSegmentIndex &&
                                         ds.id == ser.reportDataId)
          if (dataSet !== undefined){
            dataSets.push(dataSet)
          }

        })
      return dataSets
    }
  )
  return selectedDataSet

}


export const makeSegmentIndicesSelector = () => {
  const selectedSegmentIndices = createSelector(
    [
      state => state.dashboardData.dataSegmentIndices,
      (state, coords) => coords
    ],
    (allIndices, segmentCoords: SegmentCoords) => {
      if ('reportId' in segmentCoords && 'dataSegmentIndex' in segmentCoords) {
        return allIndices.filter(dsi => dsi.report_id == segmentCoords.reportId && dsi.data_segment_index == segmentCoords.dataSegmentIndex)
      } else {
        return []
      }
    }
  )
  return selectedSegmentIndices

}

export const makeFilterNameSelector = () => {
  const filterNameSelector = createSelector(
    [
      state => state.dashboardData.dataIndices,
      (state, reportId) => reportId
    ],
    (indices, reportId) => {
      const entry = indices.find(index => index.report_uuid == reportId)
      if(entry?.report_name) {
        return entry?.report_name.replace(' - ', ' — ')
      } else {
        return ''
      }
    }
  )
  return filterNameSelector

}


export const { loadDataSets, loadDataIndices, loadSegmentIndices } = dashboardDataSlice.actions

export default dashboardDataSlice.reducer
