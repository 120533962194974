import  React, { useEffect }  from 'react';
import { Classes, MenuItem, Card, Label, Button, ButtonGroup, Divider, Collapse } from "@blueprintjs/core";
import { Select, ItemRenderer, ItemPredicate } from "@blueprintjs/select";
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { selectedDashboard, selectDashboards, selectDashboard } from '../features/dashboardSlice';
import { Dashboard } from '../types/Dashboard'

const DashboardSelect = Select.ofType<Dashboard>()


export const DashboardSelector = ({handleSelect= null}:{handleSelect?:any|null}) => {
  
  const dispatch = useAppDispatch();
  const dashboards = useAppSelector(selectDashboards);
  const dashboard = useAppSelector(selectedDashboard)

  const dashboardSelect = (item: Dashboard, event?: React.SyntheticEvent<HTMLElement>) => {
    dispatch(selectDashboard(item.id))
    if(typeof handleSelect == 'function') {
      handleSelect(item.id)
    }
  }



  const dashboardRenderer: ItemRenderer<Dashboard> = (aType, { handleClick, modifiers }) => {
    return (
        <MenuItem
          active={modifiers.active}
          key={aType.id}
          text={aType.name}
          onClick={handleClick}
        />
    );
  }

  const dashboardPredicate: ItemPredicate<Dashboard> = (query: string, item: Dashboard, index?: undefined | number, exactMatch?: undefined | false | true): boolean => {
    if (item.name.toLowerCase().indexOf(query.toLowerCase()) > -1) {
      return true
    } else {
      return false
    }
  }

  return (
    <div>
      { dashboards &&
        <DashboardSelect
          items={dashboards}
          itemRenderer={dashboardRenderer}
          onItemSelect={dashboardSelect}
          itemPredicate={dashboardPredicate}
        >
          <Button text={ dashboard ? dashboard.name : 'Select A Dashboard'} rightIcon="double-caret-vertical" />
        </DashboardSelect> 
      }
    </div>
  );
}
