import { React, useEffect, useRef }  from 'react';
import { Label, ButtonGroup, Divider, Callout, Button } from '@blueprintjs/core'
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'chart.js/auto';
import 'chartjs-adapter-moment';
import { Pie, Chart, Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ChartOptions } from 'chart.js'
import { updateReportArtifactSelections, selectedReportId,  selectedReportData, selectedVis } from '../features/reportsSlice';
import { Vizualization } from '../types/Visualization';
import { transpose } from 'matrix-transpose'
import { Base64 } from 'js-base64';
import { selectPlugin } from './selectPlugin'
import { loadDataSets, selectDataIndices, makeDataSetSelector, makeFilterNameSelector } from '../features/dashboardDataSlice'
import { removeVisualizationFromSelectedDashboard } from '../features/dashboardSlice'
import { VisualizationConfigurator2 } from './VisualizationConfigurator2'
import { ArtifactSelection2 } from '../types/ArtifactSelection2'

export const Visualizer2 = ({visualization, groupType, onSelect}:{visualization: Visualization, groupType: 'reports'|'dashboards', onSelect:any}) => {
  const chartRef = useRef(null);

  const dispatch = useAppDispatch()

  const dataSelector = makeDataSetSelector()
  const data = useAppSelector(state => dataSelector(state, (visualization?.series || [])))

  const indices = useAppSelector(selectDataIndices)

  const getFilterName = (reportId: string) => {
    const entry = indices.find(index => index.report_uuid == reportId)
      if(entry?.report_name) {
        return entry?.report_name.replace(' - ', ' — ')
      } else {
        return ''
      }
  }

  const getReportData = (reportDataId: string, dataSegmentIndex: string|number, reportId: string) => {
    return data.find(rd => rd.id == reportDataId && rd.data_segment_index == dataSegmentIndex && rd.report_id == reportId)
  }

  const getDataSet = (reportDataId: string, dataSegmentIndex: string|number, reportId: string, dataSetId: string) => {
    const reportData = getReportData(reportDataId, dataSegmentIndex, reportId)
    if (reportData && reportData.datasets) {
      const ds = reportData.datasets.find(ds => ds.id == dataSetId)
      return {...ds, labels: reportData.labels}
    } else {
      return null;
    }
  }

  const deleteVis = () => {
    switch(groupType){
      case 'reports':
        
        break;
      case 'dashboards':
        dispatch(removeVisualizationFromSelectedDashboard(visualization.id))
        break;
    }

  }
 
  const trimLabels = (labels: string[]) => {
    if(labels?.length > 1) {
      let labelsMatrix = labels.map(label => {
        return label.split(' - ')
      })
      let segmentsToRemove = []
      labelsMatrix[0].forEach(segment => {
        let remove = true
        labels.forEach(label => {
          if (label.indexOf(segment) === -1){
            remove = false
          }
        })
        if(remove === true) {
          segmentsToRemove.push(segment)
        }
      })
      let trimedLabels = []
      labels.forEach(label => {
        let newLabel = label
        segmentsToRemove.forEach(segment => {
          newLabel = newLabel.replace(segment, '')
          if(newLabel.endsWith(' - ')) {
            newLabel = newLabel.slice(0, -3)
          } 
          if(newLabel.startsWith(' - ')) {
            newLabel = newLabel.slice(3)
          } 
          newLabel = newLabel.replace(' -  - ', ' - ') 
        })
        trimedLabels.push(newLabel);
      })
      return trimedLabels
    } else {
      return labels
    }
  }
 
  const genLabels = (visualization: Visualization) => {
    let reportDataSetParams = [];
    for (const series of visualization.series) {
      reportDataSetParams.push([series.reportDataId, series.dataSegmentIndex, series.reportId])
    }
    const uniqueReportDataParams =  [...new Set([...reportDataSetParams])];
    if (visualization.concatenate) {
      let labels = []
       for (const list of reportDataSetParams) {
        const reportData = getReportData(list[0], list[1], list[2])
        if (reportData) {
          labels = labels.concat(reportData.labels)
        }
      } 
      return labels
    }
    if (uniqueReportDataParams.length > 1){
      // we must pad with null data to merge the visuazation
      let labels = []
      for (const list of reportDataSetParams) {
        const reportData = getReportData(list[0], list[1], list[2])
        if (reportData?.labels && reportData.labels.length > 0) {
          if(labels.length == 0){
            labels = reportData.labels
          } else {
            labels = labels.filter(label => reportData.labels.includes(label))
          }
        } else {
          // there was no report data || or there are no labels
          return [];
        }
      }
 
      return labels
     
    } else {
      // we got the easy case
      for (const list of uniqueReportDataParams) {
        const reportData = getReportData(list[0], list[1], list[2])
        if (reportData) {
          return reportData.labels
        } else {
          return [];
        }
      }
    }
  }

  const genDatasets = (visualization: Visualization, labels: array<string|number>) => {
    let dataSets = [];
    if (visualization.concatenate) {
      let dataSet = {
        data: [],
        label: '',
        backgroundColor: [],
        borderColor: [],
        borderWidth: null,
        barThickness: null
      }
      for (const series of visualization.series) {
        if(series.dataSetId == ''){
          continue
        }
        const dataForSet = getDataSet(series.reportDataId, series.dataSegmentIndex, series.reportId, series.dataSetId)
        if(dataForSet) {
          dataSet = { 
            data: [...dataSet.data, dataForSet.data], 
            label: `${getFilterName(series.reportId)} - ${dataForSet.label}`,
            borderColor: [...dataSet.borderColor, series.borderColor],
            backgroundColor: [...dataSet.backgroundColor, series.backgroundColor],
          }
        }
      }
      return [dataSet]
    }
    for (const series of visualization.series) {
        if(series.dataSetId == ''){
          continue
        }

      const dataForSet = getDataSet(series.reportDataId, series.dataSegmentIndex, series.reportId, series.dataSetId, labels)
      if(dataForSet?.data) {
        const dsLabels = dataForSet.labels
        const firstElement = dsLabels.findIndex(label => label == labels[0])
        const set = {data: dataForSet.data.slice(firstElement), label: `${getFilterName(series.reportId)} - ${dataForSet.label}`, ...series}
        dataSets.push(set)
      }
    }
    const origSeriesLabels = dataSets.map(ds => ds.label)
    const newSeriesLabels = (trimLabels(origSeriesLabels))
    if(origSeriesLabels !== newSeriesLabels){
      let newDataSets = []
      dataSets.forEach((ds, index) => {
        const set = {...ds, label: newSeriesLabels[index]}
        newDataSets.push(set)
      })
      return newDataSets;
    }
    return dataSets;
  }

  const genVisualizationData = (visualization: Visualization) => {
    // check all series to see if they have the same labels
    // if not interpolate missing data as null
    const origLabels = genLabels(visualization)
    const labels = trimLabels(origLabels)
    const datasets = genDatasets(visualization, origLabels)
    let visData = {
      labels: labels,
      datasets: datasets
    }
    if (visualization.sortBy) {
      const sorted = sortVisData(visualization, visData)
      visData = {
        labels: sorted[0],
        datasets: datasets.map((ds, index) => {
          return {...ds, data: sorted[index+1]}
        })
      }
    }
    return visData
  }

  const sortVisData = (vis: Visualization, visData: any)  => {
    let visDataMatrix = [
      visData.labels,
      ...visData.datasets.map(dataset => dataset.data)
    ]
    if (!visDataMatrix[0]) {
      return visDataMatrix
    } 
    const sorted = transpose(visDataMatrix).sort((a,b) => {
      const sortByInt = parseInt(vis.sortBy)
      a = parseFloat(a[sortByInt + 1]) || 0
      b = parseFloat(b[sortByInt + 1]) || 0

      if (a < b) {
        return vis.orderBy === 'DESC' ? 1 : -1
      } else if (a > b) {
        return vis.orderBy === 'DESC' ? -1 : 1
      } else {
        return 0
      }
    })
    return transpose(sorted)
  }
  const handleSelect = (points) => {
    const selections = []
    visualization.xAxes.forEach((axis) => {
      const axis_name = axis.name
      const start_end = points.flat().filter(x => x.key == axis_name).map(point => point.value).sort()
      visualization.series.forEach(ser => {
        if(start_end[0] != start_end[1] && ser.dataSegmentIndex !== undefined && ser.reportId !== undefined && ser.reportDataId !== undefined) {
          const reportData = getReportData(ser.reportDataId, ser.dataSegmentIndex, ser.reportId)
          const selection:ArtifactSelection2 = {
            visualizationUUID: visualization.id,
            uuid: ser.reportDataId.split('_')[0],
            start: start_end[0],
            end: start_end[1],
            dataSegmentIndex: ser.dataSegmentIndex,
            reportId: ser.reportId,
            name:  reportData?.name.split(' - ')[0] || 'Data',
            checksums: []          
          }
          if (selections.findIndex((sel) => sel.uuid == selection.uuid) < 0) {
            selections.push(selection)
          }
        }
      }) 
    })
      if(typeof onSelect == 'function') {
        onSelect(selections)
      }

  }

  const genOptions =  (visualization: Visualization) => { 
    let options = {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: visualization.title
        },
        legend: {
          events: ['click']
        },
        select: {
          events: ['mousedown', 'mousemove', 'mouseout', 'mouseup' ],
          callback: handleSelect
        }
      },
      events: ['mousedown', 'mousemove', 'mouseout', 'mouseup', 'click' ],
      scales: {}
    } 
    for (const axis of visualization.xAxes) {
      if (visualization.chartType?.toLowerCase() !== "pie"
          && !axis.axisConfig.ticks 
          && axis.axisConfig.type !== 'time') {
        options.scales[axis.name] = {...axis.axisConfig,
          ticks: {
            callback: function(value, index, values){
              let label = this.getLabelForValue(value)
              if (typeof label === 'string' && label.length > 20) {
                return `${label.slice(0, 20)}...`
              } else {
                return label
              }
              return value
            }
          }
        }
      } else {
        options.scales[axis.name] = axis.axisConfig
      }
    }
    for (const axis of visualization.yAxes) {
      options.scales[axis.name] = { ...axis.axisConfig,
          max: parseFloatOrNull(axis.axisConfig.max),
          min: parseFloatOrNull(axis.axisConfig.min),
      }
    }
    if (visualization.xAxes.find(axis => axis.axisConfig.type == "time")) {
      options.plugins.select['enabled'] = true
    } else {
      options.plugins.select['enabled'] = false
    }
    ChartJS.register(selectPlugin)
    return options;
  }

  const parseFloatOrNull = (value) => {
    if (value == null) {
      return null 
    }
    else {
      return parseFloat(value)
    }
  }

  const visualizationTagRenderer = () => {
    switch (visualization.chartType) {
      case "Bar" || "bar": {
        return (
             <Bar
                ref={chartRef}
                data={genVisualizationData(visualization)} 
                options={genOptions(visualization)} 
                key={visualization.id}
                redraw={false}
                datasetIdKey="id"
              />
        );
      }
      case "Line" || "line": {
        return (
             <Line
                ref={chartRef}
                data={genVisualizationData(visualization)} 
                options={genOptions(visualization)} 
                key={visualization.id}
                redraw={false}
                datasetIdKey="id"
              />
        );
      }
      case "Pie" || "pie": {
        return (
             <Pie
                ref={chartRef}
                data={genVisualizationData(visualization)} 
                options={genOptions(visualization)} 
                key={visualization.id}
                redraw={false}
                datasetIdKey="id"
              />
        );
      }

    }
  }
/*
  let renderDownload = false
  useEffect(() => {
    if(visualization && chartRef?.current){
      console.log(chartRef.current)
      if (!renderDownload) {
        renderDownload = true

        console.log('Can Render Download')
      }
    }
  }, [visualization, chartRef, renderDownload])
*/
  const handleImage = () => {
    const elet = document.createElement("a");
    elet.href = chartRef.current?.toBase64Image()
    elet.setAttribute("download", `${visualization.title}.png`)
    elet.click()
  }

  const CSVData = () => {
    let chartData = genVisualizationData(visualization)
    let exportData = []
    exportData[0] = ["Name", ...chartData.labels]
    chartData.datasets.forEach(dataset => {
      exportData.push([dataset.label, ...dataset.data])
    })
    exportData = transpose(exportData)
    let csv = ""
    exportData.forEach(row => {
      let rowString = ""
      row.forEach(elet => {
        if (isNaN(elet)) {
          rowString += `"${elet}",`
        } else if (elet === null) {
          rowString += ","
        } else {
          rowString += `${elet},`
        }
      })
      csv += (rowString + "\n")
    })
    return `data:text/csv;base64,${Base64.encode(csv)}`
  }

  const handleCSV = () => {
    const elet = document.createElement("a");
    elet.href = CSVData()
    elet.setAttribute("download", `${visualization.title}.csv`)
    elet.click()
  }
  return (
    <Row>
      <Col sm={12}>
        <Row>
          <Col sm={12}>
            <Callout className="visualized">
              { visualization && visualizationTagRenderer() }
            </Callout>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
          { visualization &&
          <ButtonGroup className="data-download-options">
            <Button minimal={false} icon="media" onClick={handleImage} fill={false}>Image</Button>
            <Button minimal={false} icon="document" onClick={handleCSV} fill={false}>CSV</Button>
            <VisualizationConfigurator2 
              visualization={visualization}
              groupType={groupType}
            />
           <Button minimal={false} icon="trash" intent="danger" onClick={deleteVis} />
          </ButtonGroup>
          }
          </Col>
        </Row>
      </Col>
    </Row>
  );

}
