import  React  from 'react';
import { Callout } from "@blueprintjs/core";
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { Report } from './Report';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { mySelectedOrg} from '../features/orgsSlice';



export const DataFilters = () => {
  const user = useAppSelector((state) => state.userData.user)
  const myOrg = useAppSelector(mySelectedOrg)
  const dispatch = useAppDispatch()

  return (
    <div>
      { user && myOrg &&
      <div>
        <Report />
      </div>
      }
    </div>
  );
}
