import { featureConfig } from  '../featureConfig'
import { selectUser } from '../features/userSlice'
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useRef, useState, useEffect } from 'react';

export const Dashboard2Space = () => {

  const [grafLoggedIn, setGrafLoggedIn] = useState(false);
  const grafLogin = useRef<Window|null>();
  const [listener, setListener] = useState(false)
  const user = useAppSelector((state) => state.userData.user)

  // creates and event listener that looks for the pop up window's user logged in message
  // removes the listener if it gets a postive login
  // but it does not turn off the "listener" state variable,
  // because that would trigger the re-instalation of the event listener
  const handleLoginReport = (event) => {
    if (event.data.type === 'loginReport'){
      if(event.data.data === true) {
        setGrafLoggedIn(true)
        if(grafLogin.current !== null && grafLogin.current !== undefined){
          grafLogin.current.close()
        }
        window.removeEventListener("message", handleLoginReport, false)
      }
    }
    console.log(event)
  }
  useEffect(() => {
    if(!listener) {
        window.addEventListener("message", handleLoginReport, false)

        setListener(true)
    }
  }, [listener])

  
  const login = () => {
    grafLogin.current = window.open(featureConfig.grafana_url, 'loginWindow', 'popup=yes,width=1,height=1')
    setTimeout(() => {
      if(!grafLoggedIn){
        setGrafLoggedIn(true)
      }
    }, 3000)
  }

  useEffect(() => {
    if(!grafLoggedIn && listener){
      login()
    } 
  }, [grafLoggedIn, listener])

  return ( 
    <div>
      { grafLoggedIn &&
        <iframe src={`${featureConfig.grafana_url}`} className="talisman-embed" sandbox="allow-storage-access-by-user-activation
                 allow-scripts
                 allow-same-origin
                 allow-forms"/>
      }
    </div>
  );
}
