import { getRelativePosition } from 'chart.js/helpers'

export const selectPlugin = {
      id: 'select',
      beforeInit: (chart, args, options) => {
        chart.selectPlugin = {
          box: [],
          mouseDown: false,
          moveLast: 0,
          output: []
        }
      },
      beforeEvent: (chart, args, pluginOptions) => {
        if (pluginOptions.enabled) {
          const top = chart.chartArea.top
          const bottom = top + chart.chartArea.height
          const getXAxisData = (point) => {
            const axes = Object.entries(chart.scales)
            const points = []
            axes.forEach((axis, number) => {
              if(axis[0][0] == 'x'){
                const value = chart.scales[axis[0]].getValueForPixel(point);
                points.push({value: value, key: axis[0]})
              }
            })
            return points
          }
          const event = args.event
          let points = getRelativePosition(event, chart);
          if (event.type === 'mousedown') {
            chart.selectPlugin.mouseDown = true
            chart.selectPlugin.box[0] = [points.x, top]
            chart.selectPlugin.box[1] = [points.x, top]
          }
          if (event.type === 'mouseup') {
            if(chart.selectPlugin.mouseDown) {
              chart.selectPlugin.mouseDown = false
              chart.selectPlugin.box[1] = [points.x, bottom]
              chart.update();
              chart.selectPlugin.output = [
                getXAxisData(chart.selectPlugin.box[0][0]),
                getXAxisData(chart.selectPlugin.box[1][0])
              ]
              chart.config.options.plugins.select.callback(chart.selectPlugin.output)
            }
          }
          if (event.type === 'mousemove') {
            if(event.native.buttons == 0 && chart.selectPlugin.mouseDown) {
              chart.selectPlugin.mouseDown = false
              chart.selectPlugin.box = []
            } else if(chart.selectPlugin.mouseDown && chart.selectPlugin.offCtx) {
            const d = new Date()
              if (d.valueOf() - chart.selectPlugin.moveLast > 20) {
                chart.selectPlugin.moveLast = d.valueOf()
                chart.selectPlugin.box[1] = [points.x, bottom]
                const offCtx = chart.selectPlugin.offCtx
                const drawCanvas = document.createElement('canvas')
                drawCanvas.width = offCtx.canvas.width
                drawCanvas.height = offCtx.canvas.height
                const drawCtx = drawCanvas.getContext('2d')
                drawCtx.drawImage(offCtx.canvas, 0,0)
                drawCtx.beginPath()
                drawCtx.globalAlpha = 0.2;
                drawCtx.lineWidth = "1";
                drawCtx.fillStyle = "rgba(200,0,0)";
                drawCtx.rect(
                  chart.selectPlugin.box[0][0],
                  chart.selectPlugin.box[0][1],
                  chart.selectPlugin.box[1][0] - chart.selectPlugin.box[0][0],
                  chart.selectPlugin.box[1][1] - chart.selectPlugin.box[0][1]
                )
                drawCtx.fill();
                chart.ctx.clearRect(0,0, chart.ctx.canvas.width, chart.ctx.canvas.height)
                chart.ctx.drawImage(drawCtx.canvas, 0, 0 )
              }
            }
          }
        } 
      },
      afterRender: (chart, args, options) => {
        if (options.enabled) {
          const offScreen = document.createElement('canvas')
          const ctx =  chart.ctx
          offScreen.width = ctx.canvas.width
          offScreen.height = ctx.canvas.height
          offScreen.style.width = ctx.canvas.style.width
          offScreen.style.height = ctx.canvas.style.height
          let drawScaleX =  parseInt(ctx.canvas.style.width) / ctx.canvas.width
          let drawScaleY =  parseInt(ctx.canvas.style.height) / ctx.canvas.height

          const offCtx = offScreen.getContext('2d')
          offCtx.scale(drawScaleX, drawScaleY)
          offCtx.drawImage(ctx.canvas, 0 ,0)
          chart.selectPlugin.offCtx = offCtx
          const pt1 = chart?.selectPlugin?.box[0] || []
          const pt2 = chart?.selectPlugin?.box[1] || []
          const box = [pt1,pt2 ]
          if (typeof box !== 'undefined'
              && box.length == 2 
              && box[0].length == 2
              && box[1].length == 2){
            const ctx = chart.ctx
            ctx.beginPath()
            ctx.lineWidth = "1";
            ctx.strokeStyle = "red";
            ctx.rect(
              box[0][0],
              box[0][1],
              box[1][0] - box[0][0],
              box[1][1] - box[0][1]
            )
            ctx.stroke();
          }
        }
      }
    }

