import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { makeSelectedArtifactsForVis } from '../features/artifactsSlice'
import { SelectionModes, ColumnHeaderCell2, Cell, Column, Table2 } from "@blueprintjs/table";
import { useLazyGetArtifactsDataQuery }  from '../services/dataApi'
import { loadArtifacts } from '../features/artifactsSlice'
import { updateReportArtifactSelectionChecksums, selectedReportId } from '../features/reportsSlice';

import { updateDashboardArtifactSelectionChecksums, selectedDashboardId } from '../features/dashboardSlice'

import { featureConfig } from '../featureConfig'
import { Spinner, Card, Menu, MenuItem } from "@blueprintjs/core";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Artifact } from '../types/Artifact'
import { ArtifactSelection2 } from '../types/ArtifactSelection2'

export const SelectionViewer2 = ({selection, groupType}:{selection:ArtifactSelection2, groupType:'reports'|'dashboards'}) => {
  const dispatch = useAppDispatch()

  // ID Selectors
  const reportId = useAppSelector(selectedReportId)
  const dashboardId = useAppSelector(selectedDashboardId)
  let groupId:any;


  // DATA Selectors
  const artifactsSelector = makeSelectedArtifactsForVis();
  const selectedArtifacts = useAppSelector((state) => artifactsSelector(state, selection))

  useEffect(() => {
    console.log(selection.checksums)
  }, [selection])

  // Artifact API
  const [getSelectedArtifacts, apiSelectedArtifacts, lastSelectedPromise] = useLazyGetArtifactsDataQuery()

  // actions
  let updateArtifactSelectionChecksums: any;

  switch(groupType){
    case 'reports':
      groupId = reportId
      updateArtifactSelectionChecksums = updateReportArtifactSelectionChecksums
      break;
    case 'dashboards': 
      groupId = dashboardId
      updateArtifactSelectionChecksums = updateDashboardArtifactSelectionChecksums
      break
  }



  const [sortMap, setSortMap] = useState<number[]>([])

  const user = useAppSelector((state) => state.userData.user)

  const headers:{name: string, element: string}[] = useMemo(() => {
    return [
      {name: "Headline", element: "title"},
      {name: "URL", element: "url"},
      {name: "Publish Date", element: "published"},
      {name: "Sentiment", element: "sentiment"},
      {name: "Political Score", element: "political_score"},
      {name: "Sadness", element: "sadness"},
      {name: "Anger", element: "anger"},
      {name: "Fear", element: "fear"},
      {name: "Joy", element: "joy"},
      {name: featureConfig.surpriseLabels[user?.filterModule || 0], element: "surprise"},
    ]
  }, [])

  const clearSortMap = useCallback(() => {
    setSortMap([])
  }, [setSortMap])

  const genSortMap = (index, direction) => {
    if (selectedArtifacts?.length > 0) {
      const artifacts = [...selectedArtifacts]
      const checksumOrder = artifacts.sort((a,b) => {
        let val1 = a[headers[index].element]
        let val2 = b[headers[index].element]

        if (direction !== 'ASC') {
          const temp = val1
          val1 = val2
          val2 = temp
        }
        if (val1 < val2) {
          return -1
        } else if (val2 < val1){
          return 1
        } else if (val1 == val2) {
          return 0
        } else {
          return 0
        }

      }).map(x => x.checksum)

      let sortOrder:number[] = []
      checksumOrder.forEach(checksum => {
        sortOrder.push(selectedArtifacts.findIndex(art => art.checksum === checksum))
      })
      setSortMap(sortOrder)
    }
  }//, [selectedArtifacts, headers])

  useEffect( () => {
    if(selection.checksums.length < 1) {
      getSelectedArtifacts([selection.reportId, selection.dataSegmentIndex, selection.start / 1000, selection.end / 1000, selection.uuid])
    }
  }, [getSelectedArtifacts, selection])

  useEffect(() => {
    if ( apiSelectedArtifacts ) {
      const { originalArgs, data, status } = apiSelectedArtifacts
      if(status == "fulfilled" && originalArgs && data) { 
        dispatch(loadArtifacts([originalArgs[4], data]))
        let checksums = data.map(artifact => artifact.checksum)
        dispatch(updateArtifactSelectionChecksums([originalArgs[0], originalArgs[1], originalArgs[4], checksums]))
      }
    }
  }, [apiSelectedArtifacts, lastSelectedPromise, dispatch])

  useEffect(() => {clearSortMap()}, [selection, clearSortMap])


  const urlRenderer = (rowIndex: number, colIndex: number) => {
    const data = sortedDataCell(rowIndex, colIndex)
    return (
      <Cell>
        <a href={data} target="_blank">
          {data}
        </a>
      </Cell>
    )
  }

  const dateRenderer = (rowIndex: number, colIndex: number) => {
    const date = new Date(sortedDataCell(rowIndex, colIndex))
    return (
      <Cell>{date.toDateString()}</Cell>
    )
  }


  const cellRenderer = (rowIndex: number, colIndex: number) => {
    return (
      <Cell>{sortedDataCell(rowIndex, colIndex)}</Cell>
    )
  }

  const sortedDataCell = (rowIndex: number, colIndex: number):any => {
    let row: number = sortMap?.at(rowIndex) === undefined ? rowIndex : sortMap?.at(rowIndex) as number
    let element: Artifact|undefined = selectedArtifacts.at(row)
    let value = element?.[headers[colIndex].element]
      if (value === null || value === undefined){
        value = ''
      }
    return value
  }

  const sort = (index: number, direction:'ASC'|'DESC') => {
    clearSortMap()
    genSortMap(index, direction) 
  }


  const menuRenderer = (index: number) => {
    return (
      <Menu>
        <MenuItem text="Sort Asc" onClick={() => sort(index, 'ASC')}/>
        <MenuItem text="Sort Desc" onClick={() => sort(index, 'DESC')} />
      </Menu>
    )
  }

  const headerRenderer = (index: number) => {
    return (
      <div>
      { user &&
      <ColumnHeaderCell2 
        menuIcon="chevron-down"
        name={headers[index].name}
        menuRenderer={() => menuRenderer(index)}
      />
      }
      </div>
    )
  }

  const queryTitle = () => {
    return selection.name || 'Data'
  }

  const loading = ():boolean => {
    return !(apiSelectedArtifacts?.status == 'fulfilled' && selectedArtifacts && selectedArtifacts?.length >= 0)
  }

  return (
  <Row>
    <Col sm={12}>
    <Card className="selection-viewer">
      <h3>{queryTitle()}</h3>
      {  loading() && 
        <Spinner />
      }
      { !loading() && selectedArtifacts?.length > 0 &&
        <Table2 numRows={selectedArtifacts?.length} className="article-selection-table" cellRendererDependencies={[sortMap]} selectionModes={SelectionModes.NONE}>
          <Column columnHeaderCellRenderer={headerRenderer} cellRenderer={cellRenderer} />
          <Column columnHeaderCellRenderer={headerRenderer} cellRenderer={urlRenderer} />
          <Column columnHeaderCellRenderer={headerRenderer} cellRenderer={dateRenderer} />
          <Column columnHeaderCellRenderer={headerRenderer} cellRenderer={cellRenderer} />
          <Column columnHeaderCellRenderer={headerRenderer} cellRenderer={cellRenderer} />
          <Column columnHeaderCellRenderer={headerRenderer} cellRenderer={cellRenderer} />
          <Column columnHeaderCellRenderer={headerRenderer} cellRenderer={cellRenderer} />
          <Column columnHeaderCellRenderer={headerRenderer} cellRenderer={cellRenderer} />
          <Column columnHeaderCellRenderer={headerRenderer} cellRenderer={cellRenderer} />
          <Column columnHeaderCellRenderer={headerRenderer} cellRenderer={cellRenderer} />
        </Table2>
      }
      { !loading() && selectedArtifacts?.length == 0 && apiSelectedArtifacts?.data?.length == 0 &&
        <div>
          <h4>No Artifacts Found - Try broadening your selection.</h4>
          <p>If this is from an old report, you may need to be rerun it.</p>
        </div>
      }
    </Card>
    </Col>
  </Row>
  )

}
