import React, { useEffect } from 'react';
import { useAppDispatch } from '../../app/hooks'
import { LocationNode } from '../types/LocationNode'
import { LocationSourceCount } from '../types/LocationSourceCount'
import { useLazyGetReportSourceCountQuery } from '../services/reportApi'
import { updateSourceCount, updateSourceCountNoChildren } from '../features/locationsSlice'
import { Tag } from "@blueprintjs/core"


export const LocationItem = ({location, includeChildren}:{location:LocationNode, includeChildren:boolean}) => {

  const dispatch = useAppDispatch()    
  const path = `${location.Path}${location.Name}/${includeChildren ? '*': ''}`
  let [getSourceCount, sourceCountData, lastPromise] = useLazyGetReportSourceCountQuery()

  useEffect(() => {
    if(includeChildren){
      if(location.Total === undefined){
        getSourceCount(path)
      }
    } else {
      if(location.TotalNoChildren === undefined){
        getSourceCount(path)
      }
    }

  },  [location, includeChildren, path] )

  useEffect(() => {
    if(sourceCountData?.data) {
      if(includeChildren){
        if(location.Total != sourceCountData.data.source_count){
          dispatch(updateSourceCount([sourceCountData.data, location.UUID])) 
        }
      } else {
        if(location.TotalNoChildren != sourceCountData.data.source_count){
          dispatch(updateSourceCountNoChildren([sourceCountData.data, location.UUID])) 
        }
      }
    }
    
  }, [sourceCountData, location, dispatch])

  const totalSources = () => {
    if(includeChildren){
      return location.Total === undefined ? '...' : location.Total
    } else {
      return location.TotalNoChildren === undefined ? '...' : location.TotalNoChildren
    }  
  }
  
  return (
    <span>{location.Name} <Tag minimal={true}>{totalSources()}</Tag></span>
  )
}
