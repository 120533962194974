import  React, { useState, useEffect, useCallback }  from 'react';
import { Dialog, Toaster, Alert, Tag, Spinner, Card, Button, ButtonGroup, Divider, Collapse } from "@blueprintjs/core";
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { TabbedVisualizer } from './TabbedVisualizer'
import { selectedReportSearchQueryUsages, removeReport, updateReportData, updateStatus, load, loadData, loadAll, selectedReport, selectedReportId, selectedReportDataStatus } from '../features/reportsSlice';
import { ReportSelector } from './ReportSelector'
import { reportTestData } from '../../test/reportTestData'
import { ReportConfigurator } from './ReportConfigurator'
import { useUpdateSearchQueryUsagesMutation, useDeleteReportMutation, useCopyReportMutation, useLazyGetDataQuery, useLazyGetDataStatusQuery, useRunReportMutation, useStopReportMutation, useUpdateReportMutation, useCreateReportMutation, useGetMyReportsQuery, useRetryRunReportMutation } from '../services/reportApi'
import { ShareManager } from './ShareManager'
import { AppToaster } from "../Toaster";
import { Report as ReportType } from '../types/Report'
import { Breadcrumbs } from './Breadcrumbs'
import { UserPlusIcon } from '@heroicons/react/24/solid'
export const Report = () => {
  const report = useAppSelector(selectedReport);

  const reportId = useAppSelector(selectedReportId);
  const reportDataStatus = useAppSelector(selectedReportDataStatus);

  const dispatch = useAppDispatch()

  const [ getReportData, reportDataResponse, lastPromiseInfo ] = useLazyGetDataQuery()
 
  const { data, error, isLoading } = useGetMyReportsQuery(null)

  const [
    createReportApi, // This is the mutation trigger
    { isLoading: isCreating, isSuccess: isCreated }, // This is the destructured mutation result
  ] = useCreateReportMutation()

  const [
    updateReportApi, // This is the mutation trigger
    { isLoading: isUpdating }, // This is the destructured mutation result
  ] = useUpdateReportMutation()

  const [
    runReportApi, // This is the mutation trigger
    { isLoading: isRunning, data: runDataResponse, originalArgs: runDataResponseArgs, reset: resetRunResponse }, // This is the destructured mutation result
  ] = useRunReportMutation()

  const [
    stopReportApi, // This is the mutation trigger
    { isLoading: isStopping, data: stopDataResponse, originalArgs: stopDataResponseArgs, reset: resetStopResponse }, // This is the destructured mutation result
  ] = useStopReportMutation()

  const [
    retryRunReportApi, // This is the mutation trigger
    { isLoading: isRetryingRun, data: retryRunDataResponse, originalArgs: retryRunDataResponseArgs, reset: resetRetryRunResponse }, // This is the destructured mutation result
  ] = useRetryRunReportMutation()


  const [
    copyReportApi, // This is the mutation trigger
    { isLoading: isCopying, isSuccess: isCopied, data: copyResponse, originalArgs: copyResponseArgs }, // This is the destructured mutation result
  ] = useCopyReportMutation()

  const [
    deleteReportApi,
    { isLoading: isDeleting },
  ] = useDeleteReportMutation()

  // default to not polling at all
  const [pollingInterval, setPollingInterval] = React.useState(0)
  const [pollingStarted, setPollingStarted] = React.useState(false)

  useEffect(() => {
    setPollingStarted(false)
  }, [reportId, setPollingStarted])


  // whether or not we are pulling in data 
  const [getDataStateWaiting, setGetDataStateWaiting] = React.useState(false)

/*  const {
      data: pollDataStatusData,
      error: polDataStatusError,
      isLoading: pollDataStatusLoading,
      isFetching: pollDataStatusFetching,
      refetch: pollDataStatusRefetch
    } = useGetDataStatusQuery(reportId, {
      pollingInterval,
  });
*/
  const [startPollingDataStatus, pollDataStatusData, _] = useLazyGetDataStatusQuery( {pollingInterval })

  //const [incomingDataStatus, setIncomingDataStatus] = React.useState("");
  /*
  const managePolling = useCallback((dataStatus: string) => {
    const passedDataStatus = reportDataStatus
    const passedId = reportId
    if (dataStatus !== passedDataStatus) {
      switch(dataStatus) {
        case 'queued':
          /* falls through
        case 'refreshing':
        case 'running': 
          setPollingInterval(10000)
          break; 
        case 'stopping':
          setPollingInterval(10000)
          break;
        case 'scanning': 
          setPollingInterval(10000)
          break; 
        case 'complete':
          if(passedId) { 
            getReportData(passedId)
          }
          setPollingInterval(0)
          break;
        case 'stopped':
          /* falls through
        case 'error':
          /* falls through
        case 'empty':
          setPollingInterval(0)

      
      }
      if (passedId) {
        
        dispatch(updateStatus([passedId, dataStatus]))
      }
    }
    if ( passedId && passedDataStatus != "demo" && !pollingStarted){
      setPollingStarted(true)
      startPollingDataStatus(passedId)
    } else {
//        setPollingInterval(0)
    }



  }, [getReportData, startPollingDataStatus, pollingStarted, dispatch, reportId, reportDataStatus])
  */

  // always start polling every 10 seconds
  useEffect(() => {
    if (reportId && reportDataStatus !== 'demo' && !pollingStarted) {
        setPollingInterval(10000)
        setPollingStarted(true)
        startPollingDataStatus(reportId)
    }

  }, [reportId, reportDataStatus, pollingStarted, setPollingStarted, startPollingDataStatus])
  /*
  useEffect(() => {
    if (pollingStarted === false && reportDataStatus) {
      switch (reportDataStatus) {
        case 'queued':
          /* falls through 
        case 'refreshing':
        case 'stopping':
        case 'scanning':
        case 'running' : {
          setPollingInterval(10000)
          managePolling(reportDataStatus)
        }
      }

    }
  }, [pollingStarted, reportDataStatus,  managePolling])
  */
  useEffect(() => {
    if(isCreated == true && isCreating == false){
      AppToaster.show({message: 'New Data Filter Created'})
    }
  }, [isCreating, isCreated]);

  useEffect(() => {
    if(isCopied == true && isCopying == false){
      AppToaster.show({message: 'Data Filter Copy Completed'})
    }
  }, [isCopying, isCopied]);

  // handle Run Response
  useEffect(() => {
    if (reportDataStatus && runDataResponse && runDataResponse.dataStatus !== null && runDataResponse.dataStatus !== undefined && reportId && runDataResponseArgs?.id == reportId) { // we have a stop response
      // does it differ from current report
      if (runDataResponse.dataStatus !== reportDataStatus) {
        // if poll data response still unfulfilled?

          //managePolling(runDataResponse?.dataStatus || '')
          dispatch(updateStatus([reportId, runDataResponse.dataStatus]))
          resetRunResponse()
      } 
    }


  }, [runDataResponse, runDataResponseArgs,  reportId, reportDataStatus, /*managePolling,*/ resetRunResponse, dispatch, updateStatus ])

  // handle poll status response
  useEffect(() => {
    if (reportDataStatus && reportId && pollDataStatusData?.status == "fulfilled" 
        && pollDataStatusData.data && pollDataStatusData.data !== null && pollDataStatusData.data !== undefined && pollDataStatusData.data.dataStatus !== undefined
        && pollDataStatusData.originalArgs == reportId
        && pollDataStatusData.data?.dataStatus !== reportDataStatus
        && ( pollDataStatusData?.fulfilledTimeStamp || 0 ) > Date.now() - 2000
      ) {
      
      dispatch(updateStatus([reportId, pollDataStatusData.data.dataStatus]))
      //managePolling(pollDataStatusData.data?.dataStatus || '')
    }

  }, [reportDataStatus, reportId, pollDataStatusData, dispatch, updateStatus /*, managePolling */])

  // handle stop response
  useEffect(() => {
    if (reportDataStatus && stopDataResponse && stopDataResponse.dataStatus !== null && stopDataResponse.dataStatus !== undefined && reportId && stopDataResponseArgs?.id == reportId) { // we have a run response
      // does it differ from current report
      if (stopDataResponse.dataStatus !== reportDataStatus) {
        dispatch(updateStatus([reportId, stopDataResponse.dataStatus]))
        //managePolling(stopDataResponse?.dataStatus || '')
        resetStopResponse()
      } 
    }


  }, [stopDataResponse, stopDataResponseArgs,  reportId, reportDataStatus, /*managePolling, */resetStopResponse, dispatch, updateStatus ])

  // handle retry response
  useEffect(() => {
    if (reportDataStatus && retryRunDataResponse && retryRunDataResponse.dataStatus !== null && retryRunDataResponse.dataStatus !== undefined && reportId && retryRunDataResponseArgs?.id == reportId) { // we have a run response
      // does it differ from current report
      if (retryRunDataResponse.dataStatus !== reportDataStatus) {
        dispatch(updateStatus([reportId, retryRunDataResponse.dataStatus]))
        //managePolling(stopDataResponse?.dataStatus || '')
        resetRetryRunResponse()
      } 
    }


  }, [retryRunDataResponse, retryRunDataResponseArgs,  reportId, reportDataStatus, /*managePolling, */resetRetryRunResponse, dispatch, updateStatus ])




  useEffect(() => {
    if (copyResponse ) {
       dispatch(load(copyResponse))
    }
  }, [copyResponse])

  useEffect(() => {
    if (data) {
      dispatch(loadAll(data)); 
    }
  }, [data, dispatch])

  useEffect(() => {
    if ( reportDataResponse && reportDataResponse.status == "fulfilled" && reportDataResponse.originalArgs && reportDataResponse.data) {
      dispatch(loadData([reportDataResponse.originalArgs, reportDataResponse.data])) 
      setGetDataStateWaiting(false)
    }
  }, [reportDataResponse,  dispatch, setGetDataStateWaiting])

  useEffect(() => {
    if (report && !report.reportData && !getDataStateWaiting) {
      if (reportDataResponse.isUninitialized){
        setGetDataStateWaiting(true)
        getReportData(report.id)
      } else if (!reportDataResponse.isLoading && !reportDataResponse.isFetching && reportDataResponse.isSuccess) {
        setGetDataStateWaiting(true)
        getReportData(report.id)
      }
    }

  }, [report, reportDataResponse, getDataStateWaiting, setGetDataStateWaiting])

  const searchQueryUsages = useAppSelector(selectedReportSearchQueryUsages)
  const [
    updateSearchQueryUsagesApi, // This is the mutation trigger
    { isLoading: isUpdatingUsages }, // This is the destructured mutation result
  ] = useUpdateSearchQueryUsagesMutation()

  const updateReport = () => {

    const currentUUIDs = searchQueryUsages?.filter(x => x.active === true)?.map(x => x.search_query_id) || []
    if (reportId) {
      updateSearchQueryUsagesApi([reportId, currentUUIDs])
    }
    if (report) {
      updateReportApi(report)
    }
  }
  const runReport = () => {
    if (report) {
      runReportApi(report)
    }
  }

  const stopReport = () => {
    if (report) {
      stopReportApi(report)
    }
  }
  const retryRunReport = () => {
    if (report) {
      retryRunReportApi(report)
    }
  }


  const copyReport = () => {
    AppToaster.show({message: 'Copying Data Filter... This may take some time.'})
    if (report) {
      copyReportApi(report)
    }
  }

  const deleteReport = (report: ReportType) => {
    dispatch(removeReport(report.id))
    deleteReportApi(report)
    setDeleteOpen(false)
  }
  const createReport = (event: any) => {
    AppToaster.show({message: 'Creating New Data Filter'})
//    dispatch(create(
    createReportApi(
     {
        config: {
          name: "Untitled",
          locations: [],
          locationPaths: [],
          artifactTypes: [],
          dateRange: [null,null],
          query: null,
          lastRun: null,
          saved: false,
          comparisons: [],
          pipeline_data_stage: 'enabled',
          description: ''
        },
        reportData: [],
        visualizations: []
      } as Partial<ReportType>
    );
    //));
  }

  const isProcessingAction = ():boolean => {
    return isRunning || isStopping || isRetryingRun
  }

  const isSaved = () => {
    return report !== null && report?.config?.saved == true && report?.config?.search_query_usages_saved == true
  }

  const retryRunEnabled = () => {
    return report !== null && isSaved() && report?.dataStatus == 'error' && !isProcessingAction()
  }
  const stopEnabled = () => {
    return report !== null && isSaved() && (report?.dataStatus == 'running' || report?.dataStatus == 'scanning') && !isProcessingAction()
  }

  const runEnabled = () => {
    return report !== null && isSaved() && (report?.dataStatus == 'error' || report?.dataStatus == 'complete' || report?.dataStatus == 'empty' || report?.dataStatus == 'stopped' ) && !isProcessingAction()
  }




  const [visualizerOpen, setVisualizerOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  
  const reportSaved = (): boolean => {
    if(report) {
      if (report.config.saved && report.config.search_query_usages_saved) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }

  } 
  const breadcrumbs =  [
    {href: "/", text: 'Home'},
    {href: "/data-filters", text: 'Data Filters'}
  ]

  return (
    <Row>
        <Col>
          <Card>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <Divider />
            { isLoading && 
              <Spinner size={30}/> 
            } 
            <ButtonGroup minimal={true}>
              <ReportSelector />
              <Divider />
              <Button icon="add" text="Create a Data Filter" onClick={createReport} disabled={(report != undefined && report != null) && !reportSaved()}/>
              { report &&
                <div>
                <Button 
                  text="Copy Data Filter"
                  icon="duplicate" 
                  disabled={!reportSaved()}
                  onClick={copyReport}
                />
                <Button
                  text="Manage Editor Access"
                  icon={<UserPlusIcon className="h-16 w-16"/>}
                  disabled={!reportSaved()}
                  active={shareOpen}
                  onClick={() => shareOpen ? setShareOpen(false) : setShareOpen(true) }
                />
                </div>
              }
              <Divider />
              { report &&
                <div>
                  <Button icon="trash" intent="danger" onClick={() => setDeleteOpen(true)}/>
                  <Alert
                    isOpen={deleteOpen}
                    intent="danger"
                    icon="trash"
                    canOutsideClickCancel={true}
                    canEscapeKeyCancel={true}
                    confirmButtonText="Delete it!"
                    cancelButtonText="Cancel"
                    onCancel={() => setDeleteOpen(false)}
                    onConfirm={() => deleteReport(report)} 
                  >Permanently Delete this Report? This will also remove any unused queries from your Query Book.</Alert>
                </div>
              }
            </ButtonGroup>
            { report &&
            <Collapse isOpen={report != null}>

                <Dialog isOpen={shareOpen} title="Manage Editor Access" onClose={(event: any) => setShareOpen(false)} className="manage-share-dialog">
                  <Card>
                    <Row>
                      <Col>
                        <ShareManager groupType="reports" />
                      </Col>
                    </Row>
                  </Card>
                </Dialog>


              <ReportConfigurator/>
              <Divider />
              <Row>
                <Col>
                  <ButtonGroup minimal={true}>
                    <Button 
                      text={ report.config.saved && report.config.search_query_usages_saved? 'Saved' : 'Save'}
                      disabled={ reportSaved() }
                      onClick={ updateReport }
                    />
                    <Divider />
                    <Button 
                      text="Run" 
                      icon="play"
                      disabled={!runEnabled()}
                      onClick={runReport}
                    />
                    <Button 
                      text="Stop" 
                      icon="stop"
                      disabled={!stopEnabled()}
                      onClick={stopReport}
                    />
                    <Button 
                      text="Retry" 
                      icon="reset"
                      disabled={!retryRunEnabled()}
                      onClick={retryRunReport}
                    />
                    <Divider />
                    <Tag>
                      { report.dataStatus }
                    </Tag>
                    <Divider />
                    <Button 
                      text="Visualize (old)" 
                      onClick={() => visualizerOpen ? setVisualizerOpen(false) : setVisualizerOpen(true) }
                      active={visualizerOpen}
                    />

                  </ButtonGroup>
                </Col>
              </Row>
              <Row>
                <Col>   
                  <Collapse isOpen={visualizerOpen}>
                      <Divider/>
                      <Row>
                        <Col>
                          { (reportDataResponse.isLoading || reportDataResponse.isFetching || reportDataResponse.isUninitialized) &&
                            <Spinner size={30}/>              
                          }
                          { (!reportDataResponse.isLoading && !reportDataResponse.isFetching && ( !reportDataResponse.isUninitialized || report.reportData)) &&
                            <TabbedVisualizer />
                          }
                        </Col>
                      </Row>
                  </Collapse>
                </Col>
              </Row>
            </Collapse>
          }
          </Card>
        </Col>
      </Row>
  );

}
